import { Component, Input, Output, EventEmitter, Renderer2, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import pageSettings from '../../config/page-settings';
import { GlobalService, LoginService, StorageService } from '../../service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiRes } from '../../interface';
import { TranslateService } from '@ngx-translate/core';
@Component({
	selector: 'header',
	templateUrl: './header.component.html',
})
export class HeaderComponent implements OnDestroy {
	@Input() pageSidebarTwo;
	@Output() toggleSidebarRightCollapsed = new EventEmitter<boolean>();
	@Output() toggleMobileSidebar = new EventEmitter<boolean>();
	@Output() toggleMobileRightSidebar = new EventEmitter<boolean>();
	pageSettings = pageSettings;
	notifications = [
		{ title: 'New User Registered', icon: 'ti-user', color: 'bg-success', content: 'Check your account', timeline: '22/10/2022' },
		{ title: 'Pending payment', icon: 'ti-user', color: 'bg-success', content: 'Check your account. You have pending payments', timeline: '22/10/2022' },
	]


	mobileSidebarToggle() {
		this.toggleMobileSidebar.emit(true);
	}
	mobileRightSidebarToggle() {
		this.toggleMobileRightSidebar.emit(true);
	}
	toggleSidebarRight() {
		this.toggleSidebarRightCollapsed.emit(true);
	}

	mobileTopMenuToggle() {
		this.pageSettings.pageMobileTopMenuToggled = !this.pageSettings.pageMobileTopMenuToggled;
	}

	mobileMegaMenuToggle() {
		this.pageSettings.pageMobileMegaMenuToggled = !this.pageSettings.pageMobileMegaMenuToggled;
	}
	logout() {
		window.dispatchEvent(new CustomEvent('login:logout'));
	}
	changePassword(content) {
		this.providerLogin.row.Password = null;
		this.providerLogin.row.NewPassword = null;
		this.providerLogin.row.ConfirmPassword = null;
		this.providerLogin.row.ChangePassword = 1;
		this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
	}
	updatePassword() {
		if (this.providerLogin.row.NewPassword != this.providerLogin.row.ConfirmPassword) {
      this._translate.get('PASSWORD_NOMATCH').subscribe(
        (pTranslation) => {
          this.providerLogin.loginError = pTranslation;
          //this.functionService.showAlert(pTranslation);
        }
      );
      return;
    }
		this.providerLogin.edit().then((pApiRes:ApiRes) =>{ 
			if (pApiRes.ok) {
				this.modalService.dismissAll();
			}
		})
	}
	private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
	ngOnDestroy() {
		this.pageSettings.pageMobileTopMenuToggled = false;
		this.pageSettings.pageMobileMegaMenuToggled = false;
	}
	closeResult: string;
	constructor(
		public global: GlobalService,
		private renderer: Renderer2,
		private _router: Router,
		private _storage: StorageService,
		private modalService: NgbModal,
		private providerLogin: LoginService,
    private _translate: TranslateService,
	) { }
}
