import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TerminalService } from '../../../../service';

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.css']
})
export class TerminalComponent implements OnInit {

  nameBranch: string;
  nameMerchant: string;

  constructor(
    public provider: TerminalService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(params => {
      this.provider.search.params.IdBranch = params.IdBranch;
      this.nameBranch = params.NameBranch;
      this.nameMerchant = params.NameMerchant;
      this.provider.fetch();
    });
   }

  ngOnInit(): void {
  }

}
