import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Branch } from '../../../../../interface';
import { ConfigService, BranchService, TerminalService } from '../../../../../service';

@Component({
  selector: 'app-branch-list',
  templateUrl: './branch-list.component.html',
  styleUrls: ['./branch-list.component.css'],
  //inputs: ['IdMerchantBusiness']
})
export class BranchListComponent implements OnInit {

  closeResult: string;
  nameMerchant: string;
  idMerchant: number;
  //IdMerchantBusiness: number;

  constructor(
    private modalService: NgbModal,
    public translate: TranslateService,
    public config: ConfigService,
    public provider: BranchService,
    private route: ActivatedRoute,
    private _router: Router
  ) { 
   // this.provider.search.params.IdMerchantBusiness = this.IdMerchantBusiness;
   this.provider.init();
   //this.provider.search.params.IdMerchantBusiness=1;
   this.route.params.subscribe(params => {
      this.provider.search.params.IdMerchantBusiness = params.IdMerchant;
      this.nameMerchant = params.NameMerchant;
      this.idMerchant = params.IdMerchant;
      this.provider.fetch();
    });
  }

  ngOnInit(): void {
  }
  closeModal() {
    this.modalService.dismissAll();
  }
  open(content, size) {
    this.modalService.open(content, { windowClass: 'modal-pos-item', size: size }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  goTerminals(pRow: Branch = null){
    this._router.navigate(['/bo-terminal', pRow.IdBranch, pRow.BranchName, this.idMerchant, this.nameMerchant]);
  }
  async setAction(pPage: string, pRow: Branch = null) {
    if (pPage == 'Edit' && pRow) { this.provider.row = pRow; }
    this.provider.action = pPage;
  }

}
