import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import pageSettings from '../../../config/page-settings';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.css']
})
export class AccessComponent implements OnInit {
  bg;
  bgList;
  app;
  pageSettings = pageSettings;

  constructor(private router: Router) {
    this.pageSettings.pageEmpty = true;
  }

  ngOnDestroy() {
    this.pageSettings.pageEmpty = false;
  }

  ngOnInit() {
    this.bg = '/assets/img/login-bg/login-bg-17.jpg';
  }

  onNavigate(url: string, goOut = false) {
    if (goOut) {
      window.location.href = url;
    } else {
      this.router.navigate([url]);
    }
  }


}
