var pageMenusAdmin = [
  {
    'icon': 'fa fa-th-large',
    'title': 'Home',
    'url': '/home'
  },
  {
    'icon': 'fa fa-th-large',
    'title': 'Merchants',
    'url': '/bo-merchant'
  },
  {
    'icon': 'fa fa-th-large',
    'title': 'Merchant BackOffice',
    'url': '/bo-merchant-back-office'
  }
// {
//   'icon': 'fa fa-th-large',
//   'title': 'Usuarios',
//   'url': '/bo-userlist'
// },
// {
//   'icon': 'fa fa-th-large',
//   'title': 'Crear usuario',
//   'url': '/bo-create-user'
// }
];

export default pageMenusAdmin;
