import { Injectable } from '@angular/core';
import { ApiRes, Parameter } from '../interface';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ParameterService {
  row:Parameter;
  rows:Parameter[];
  private _method:string;
  constructor(
    private _api:ApiService
    , private _storage:StorageService
    ) { this.init(); }
  async init() {
    await this._storage.del('token');
    this._method = 'Parameter';
    this.row = {
      Parameter: null,
      Value    : null,
    }
  }
  fetch() {
    let promise:Promise<ApiRes> = this._api.post(`${this._method}/Get`);
    promise.then((pApiRes:ApiRes) => {
      this.rows = pApiRes.data;
    });
    return promise;
  }
}
