import { Injectable } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { TableOptions, ApiRes, Branch } from '../interface';
import { ApiService } from './api.service';
import { GlobalService } from './global.service';
import { TerminalService } from './terminal.service';

interface BranchSearch {
  Search?: string;
  StartLimit: number;
  EndLimit: number;
  Count?: number;
  Order?: string;
  IdTerminal?: string;
}

@Injectable({
  providedIn: 'root'
})
export class BranchService {
  delProcessing: boolean = false;
  editProcessing: boolean = false;
  addProcessing: boolean = false;
  getProcessing: boolean = false;
  row: Branch;
  rows: Branch[] = [];

  private _method: string = 'Branch';
  private _search: BranchSearch;
  get action(): string { return this._action; }
  set action(pAction: string) {
    this._action = pAction;
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }
  private _action: string = 'List';
  search: TableOptions = {
    search: null,
    page: 1,
    count: 0,
    size: 10,
    sizes: [10, 25, 50, 100],
    sort: 'IdBranch_asc',
    params: {
      IdMerchantBusiness: null,
    },
    columnMode: ColumnMode,
    columnActions: (window.innerWidth < 960) ? 1 : 4//Inicia en cero
  };
  constructor(
    private _api: ApiService,
    private _providerTerminal: TerminalService,
    private _global: GlobalService,) { this.init(); }
  init() { this._initRow(); }
  fetch(pAppend: boolean = false): Promise<ApiRes> {
    this._initRow();
    this.getProcessing = true;
    this._search = {
      Search: this.search.params,
      StartLimit: (this.search.page * this.search.size) - this.search.size + 1,
      EndLimit: this.search.page * this.search.size,
      Order: this.search.sort,
    }
    const promise: Promise<ApiRes> = this._api.post(`${this._method}/Get`, this.search.params);
    promise.then((pApiRes: ApiRes) => {
      if (pApiRes.ok) {
        this.search.count = pApiRes.recordsTotal;
        this._addRows(pAppend, pApiRes.data);

      } else {
        this._initRows();
      }
      this.getProcessing = false;
    });
    return promise;
  }
  add() {
    if (this.row.BranchPhone.length == 8) {
      this.row.BranchPhone = '+506' + this.row.BranchPhone;
    }
    if (this.row.BranchPhone.length == 11) {
      this.row.BranchPhone = '+' + this.row.BranchPhone;
    }
    this.addProcessing = true;
    const promise: Promise<ApiRes> = this._api.post(`${this._method}/Add`, this.row);
    promise.then((pApiRes: ApiRes) => {
      if (pApiRes.ok) {
        this.action = 'List';
        this.fetch();
      } else {
        this._global.showAlert("Error", pApiRes.info);
      }
      this.addProcessing = false;
    });
    return promise;
  }
  edit() {
    this.editProcessing = true;
    const promise: Promise<ApiRes> = this._api.post(`${this._method}/Edit`, this.row);
    promise.then((pApiRes: ApiRes) => {
      if (!pApiRes.ok) {
        this._global.showAlert("Error", pApiRes.info);
      } else {
        this.action = 'List';
        this.fetch();
      }
      this.editProcessing = false;
    });
    return promise;
  }
  del(pEdit: boolean = false) {
    this.delProcessing = true;
    const promise: Promise<ApiRes> = this._api.post(`${this._method}/Del`, this.row);
    promise.then((pApiRes: ApiRes) => {
      if (pApiRes.ok) {
        this.action = 'List';
        this.fetch();
      } else {
        this._global.showAlert("Error", pApiRes.info);
      }
      this.delProcessing = false;
    });
    return promise;
  }
  private async _addRows(pAppend: Boolean = false, pData: Branch[] = null) {
    if (pAppend) {
      this.rows = [...this.rows, ...pData];
    } else {
      // let pDataWithTerminalList = await Promise.all(
      //   pData.map(async (q) => {
      //     this._terminalService.search.params.IdBranch = q.IdBranch;
      //     let result = await this._terminalService.fetch();
      //     const obj = {
      //       BranchEmail: q.BranchEmail,
      //       BranchName: q.BranchName, BranchPhone: q.BranchPhone,
      //        IdBranch: q.IdBranch, IdMerchantBusiness: q.IdMerchantBusiness, TerminalList: result.data
      //     };
      //     return obj;
      //   })
      // )
      //this.rows = pDataWithTerminalList;
      if (pData.length>0) {
        this._providerTerminal.init();
        
        for (let i = 0; i < pData.length; i++) {
          this._providerTerminal.search.params.IdBranch = pData[i].IdBranch;
          await this._providerTerminal.fetch();
          pData[i].TerminalList = this._providerTerminal.rows;
        }
      }
      this.rows = pData;
    }
  }
  //Para manejo de tabla
  async onSearch(pSearch: KeyboardEvent = null) {
    if (!pSearch || pSearch.key == 'Enter') {
      await this.fetch();
    }
  }
  onPage(pPage: number) {
    this.search.page = pPage;
    this.fetch();
  }
  onSort(pSort: string) {
    this.search.sort = pSort;
    this.fetch();
  }
  onTableSizeChange(pEvent: Event) { this.fetch(); }
  onActivate(pTable: DatatableComponent, pEvent) {
    if (pEvent.type == 'click' && pEvent.cellIndex != this.search.columnActions) {
      pTable.rowDetail.toggleExpandRow(pEvent.row);
    }
  }
  private _initRow() { this.row = {}; }
  private _initRows() { this.rows = []; }
}