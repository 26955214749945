import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Terminal } from '../../../../../interface';
import { ConfigService, GlobalService, TerminalService } from '../../../../../service';

@Component({
  selector: 'app-terminal-list',
  templateUrl: './terminal-list.component.html',
  styleUrls: ['./terminal-list.component.css']
})
export class TerminalListComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    public config: ConfigService,
    public global  : GlobalService,
    public provider: TerminalService,
  ) { }

  ngOnInit(): void {
    window.addEventListener('Terminal:fetch', () => { this.provider.fetch(); });
  }

  refresh() { window.dispatchEvent(new CustomEvent('Terminal:fetch')); }

  async onFetch() {
    this.provider.init();
    this.provider.getDataProcessing = true;
    await this.provider.fetch().then(pApiRes => {
      this.provider.getDataProcessing = false;
    });
  }
  async del(pRow:Terminal) {
    const title = "Delete this terminal?";//(await this.global.translate('DoYouWantDeleteRecord')).toString();
    const text = pRow.Email; //`${pRow.id} - ${pRow.text}`;
    this.provider.row = pRow;
    this.global.showAlertYesNo(title, text.toString(), () => this.provider.del());
  }
  async setAction(pPage: string, pRow: Terminal = null) {
    if (pPage == 'Edit' && pRow) { this.provider.row = pRow; }
    this.provider.action = pPage;
  }

}
