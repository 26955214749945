import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import pageSettings from '../../../config/page-settings';
import { CountryService } from '../../../service/country.service';
import { ConfigService, LoginDeviceService, LoginService } from '../../../service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'], 
})
export class LoginComponent implements OnDestroy, OnInit, AfterViewChecked {
  @ViewChild('tabset') tabset: any;
  pageSettings = pageSettings;
  emailPattern = /^(?!(?:(?:\x22?\x5C[\x00-\x7E]\x22?)|(?:\x22?[^\x5C\x22]\x22?)){255,})(?!(?:(?:\x22?\x5C[\x00-\x7E]\x22?)|(?:\x22?[^\x5C\x22]\x22?)){65,}@)(?:(?:[\x21\x23-\x27\x2A\x2B\x2D\x2F-\x39\x3D\x3F\x5E-\x7E]+)|(?:\x22(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|(?:\x5C[\x00-\x7F]))*\x22))(?:\.(?:(?:[\x21\x23-\x27\x2A\x2B\x2D\x2F-\x39\x3D\x3F\x5E-\x7E]+)|(?:\x22(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|(?:\x5C[\x00-\x7F]))*\x22)))*@(?:(?:(?!.*[^.]{64,})(?:(?:(?:xn--)?[a-z0-9]+(?:-[a-z0-9]+)*\.){1,126}){1,}(?:(?:[a-z][a-z0-9]*)|(?:(?:xn--)[a-z0-9]+))(?:-[a-z0-9]+)*)|(?:\[(?:(?:IPv6:(?:(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){7})|(?:(?!(?:.*[a-f0-9][:\]]){7,})(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,5})?::(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,5})?)))|(?:(?:IPv6:(?:(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){5}:)|(?:(?!(?:.*[a-f0-9]:){5,})(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,3})?::(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,3}:)?)))?(?:(?:25[0-5])|(?:2[0-4][0-9])|(?:1[0-9]{2})|(?:[1-9]?[0-9]))(?:\.(?:(?:25[0-5])|(?:2[0-4][0-9])|(?:1[0-9]{2})|(?:[1-9]?[0-9]))){3}))\]))$/;
  error = '';
  constructor(
    private router: Router, 
    private renderer: Renderer2,
    public contryProvider: CountryService,
    public loginDeviceServiceProvider: LoginDeviceService,
    public loginProvider: LoginService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    public configService: ConfigService
    ) {
    this.contryProvider.search.sort = 'IdCountry_asc';
    this.pageSettings.pageEmpty = true;
    this.renderer.addClass(document.body, 'bg-white');
  }
  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.loginDeviceServiceProvider.init();
    this.loginDeviceServiceProvider.addEdit('v1.0.0');
    this.loadDefaultCountry();
  }

  async loadDefaultCountry() {
    await this.contryProvider.get();
    this.loginProvider.row.PhonePrefix = this.contryProvider.rows[0].PhonePrefix;
    this.loginProvider.row.PhoneFormat = this.contryProvider.rows[0].PhoneFormat;
    this.loginProvider.row.PhoneRegex = this.contryProvider.rows[0].PhoneRegex;
    this.loginProvider.row.IdCountry = this.contryProvider.rows[0].IdCountry;
  }

  ngOnDestroy() {
    this.pageSettings.pageEmpty = false;
    this.renderer.removeClass(document.body, 'bg-white');
  }

  formSubmit(f: NgForm) {
    this.router.navigate(['dashboard/v3']);
  }

  onChageCountry(country) {
    this.loginProvider.row.PhonePrefix = this.contryProvider.rows.find(item => item.IdCountry == country).PhonePrefix;
    this.loginProvider.row.PhoneFormat = this.contryProvider.rows.find(item => item.IdCountry == country).PhoneFormat;
    this.loginProvider.row.PhoneRegex = this.contryProvider.rows.find(item => item.IdCountry == country).PhoneRegex;
    this.loginProvider.row.IdCountry = this.contryProvider.rows.find(item => item.IdCountry == country).IdCountry;
  }

  async login() {
    await this.loginDeviceServiceProvider.init();
    await this.loginDeviceServiceProvider.addEdit('V1.0.2.2');
    let response = await this.loginProvider.login();
    if (response.ok) {
      await this.configService.init();
      this.router.navigate(['charges']);
    } else {
      this.error = response.info;
      setTimeout(() => {
        this.error = '';
      }, 3000)
    }
  }

}
