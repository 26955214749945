import { Injectable } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  //Variables de la clase
  words;
  closeResult: string;
  constructor(
    private _translate: TranslateService,
    private _modalService: NgbModal,
  ) {
    this.init();
  }
  async init() {
    this.words = await this._translate.get([
      'WITH'
      , 'CLOSED_WITH'
      , 'DISMISSED'
      , 'BY_PRESSING_ESC'
      , 'BY_CLICKING_BACKDROP'
      , 'ERROR_OCCURED'
    ]).toPromise();
  }
  showModal(pModal) {
    this._modalService.open(pModal).result.then((result) => {
      this.closeResult = `${this.words.CLOSED_WITH}: ${result}`;
    }, (reason) => {
      this.closeResult = `${this.words.DISMISSED} ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return this.words.BY_PRESSING_ESC;
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return this.words.BY_CLICKING_BACKDROP;
    } else {
      return  `${this.words.WITH}: ${reason}`;
    }
  }
  showAlert(pTitle:string, pText:string, pType:any = 'error', returnPromise:boolean = false) {
    const returnVal = Swal.fire({icon: pType, title: pTitle, text: pText});
    if(returnPromise){
      return returnVal;
    }
  }
  async showAlertYesNo(
    pTitle:string,
    pText:string,
    fYes:Function = (pValue) => { console.log(`Say Yes: ${pValue}`); },
    fNo:Function = (pValue) => { console.log(`Say No: ${pValue}`); }
  ) {
    //const yesNo:any = ['Yes', 'No'];
    let textYes = 'Yes';
    let textNo = 'No';

    this._translate.get('YES').subscribe(
      (pTranslation) => {
        textYes = pTranslation;
      }
    );
    this._translate.get('NO').subscribe(
      (pTranslation) => {
        textNo = pTranslation;
      }
    );
    this._translate.get(pTitle).subscribe(
      (pTranslation) => {
        pTitle = pTranslation;
      }
    );
    

    const alert = await Swal.fire({
      title: pTitle,
      text: pText,
      showCancelButton: true,
      confirmButtonText: textYes,
      cancelButtonText: textNo,
    });
    if (alert.isConfirmed) {
      fYes(alert.value);
    } else if (alert.isDenied) {
      fNo(alert.value);
    }
  }
 
}