import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService, GlobalService } from '../../../../../service';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { UserListService } from '../../../../../service/user.service';
import { UserData, UserDataRow } from '../../../../../interface/user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-merchant-back-office-list',
  templateUrl: './merchant-back-office-list.component.html',
  styleUrls: ['./merchant-back-office-list.component.css']
})

export class MerchantBackOfficeListComponent implements OnInit {

  @Output() crossOffSetAction = new EventEmitter<any>();

  ColumnMode = ColumnMode;
  // @ViewChild(DatatableComponent) table: DatatableComponent;
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  // closeResult: string;
  // visible: boolean = false;
  // visiblePaymentLink: boolean = false;
  // ColumnMode = ColumnMode;
  // expandedElement: PaymentLinks | null;
  // selection = new SelectionModel<PaymentLinks>(true, []);
  // url: string;
  // fileUrl: any;
  constructor(
    public translate: TranslateService,
    public config: ConfigService,
    public provider: UserListService,
    private router: Router,
    public global  : GlobalService,
    ) {}

    async ngOnInit(): Promise<void> {
      //Listeners
      window.addEventListener('Merchant:fetch', () => { this.provider.fetch(); });
      this.provider.initSearch(4)
      await this.onFetch();
    }
    async onFetch() {
      var search = this.provider.search.search;
      this.provider.init();
      this.provider.getDataProcessing = true;
      
      // this.provider.search.search = 'IdMerchant';
      // this.provider.search.params = {
      //   IdMerchant: null,
      //   IdLoginRole:4
      // }
      this.provider.cleanRow();
      this.provider.IdLoginRol = 4;
      this.provider.search.search = search;
      
      await this.provider.fetch().then(pApiRes => {
        this.provider.getDataProcessing = false;
      });
      this.provider.currentStep.stepIndex=1;
      this.provider.businessProfessionalSelected = 1;
    }
    refresh() { window.dispatchEvent(new CustomEvent('Merchant:fetch')); }
    onSelect(row) {
      this.router.navigate(['/bo-branch', row.IdMerchant, row.NameMerchant ]);
    }
    async del(pRow:UserData) {
      const title = "Delete this record?";//(await this.global.translate('DoYouWantDeleteRecord')).toString();
      const text = pRow.NameMerchant +' '+pRow.LastNameMerchant; //`${pRow.id} - ${pRow.text}`;
      this.provider.row = pRow;
      this.global.showAlertYesNo(title, text, () => this.provider.del());
    }
    async setAction(pPage: string, pRow: UserData = null) {
      if (pPage == 'Edit' && pRow) { this.provider.row = pRow; }
      this.provider.action = pPage;
    }
    onSelectBlue(value) {
    }
    updateFilter(event) {
/*       const val = event.target.value.toLowerCase();
  
      // filter our data
      const temp = this.temp.filter(function (d) {
        return d.name.toLowerCase().indexOf(val) !== -1 || !val;
      });
  
      // update the rows
      this.rows = temp;
      // Whenever the filter changes, always go back to the first page
      this.table.offset = 0; */
    }
}
export interface PaymentLinks {
  idLink: number;
  link: string;
  cost: string;
  soldUnities: string;
  maxUnities: string;
  activeUntil: string;
  status: number;
  shareLink: string;
  userTransactions: Array<any>;
}

