import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BranchService } from '../../../../../service/branch.service';

@Component({
  selector: 'app-branch-form',
  templateUrl: './branch-form.component.html',
  styleUrls: ['./branch-form.component.css'],
  inputs: ['IdMerchantBusiness']
})
export class BranchFormComponent implements OnInit {

  IdMerchantBusiness: number;

  constructor(
    public provider: BranchService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(params => {
      this.provider.row.IdMerchantBusiness = params.IdMerchant;
    });
   }

  ngOnInit(): void {
  }

}
