import { Injectable } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { ApiRes, Currency, TableOptions } from '../interface';
import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  delProcessing : boolean = false;
  editProcessing: boolean = false;
  addProcessing : boolean = false;
  getProcessing : boolean = false;
  search: TableOptions = {
    search: null,
    page  : 1,
    count : 0,
    size  : 100,
    sizes : [10, 25, 50, 100],
    sort  : 'IdCurrency_desc',
    params: {
      IdCurrency: null,
    },
    columnMode: ColumnMode,
    columnActions: 4//Inicia en cero
  };
  row : Currency;
  rows: Currency[];
  private _method: string = 'Currency';
  get _search() {
    let toReturn = {
      StartLimit : (this.search.page * this.search.size) - this.search.size + 1,
      EndLimit   : this.search.page * this.search.size,
      Order      : this.search.sort,
      Search     : this.search.search,
    };
    Object.entries(this.search.params).forEach(([key, value]) => {
      toReturn[key] = value;
    });
    return toReturn;
  }
  constructor(
    private _api:ApiService,
  ) { this.init(); }
  init() { this._setRow(); }
  //Set Row y Rows
  private _setRow(pAppend:Boolean = false, pRow:Currency = {}) {
    if (pAppend) {
      this.addRow(pRow);
    } else {
      this.row = pRow;
    }
  }
  private _setRows(pAppend:Boolean = false, pData:Currency[] = null) {
    if (pAppend) {
      this.rows = [...this.rows, ...pData];
    } else {
      this.rows = pData;
    }
  }
  //Obtención de datos
  get(pAppend:boolean = false) {
    this.getProcessing = true;
    const promise:Promise<ApiRes> = this._api.post(`${this._method}/Get`, this._search);
    promise.then((pApiRes:ApiRes) => {
      if (pApiRes.ok) {
        this.search.count = pApiRes.recordsTotal;
        this._setRows(pAppend, pApiRes.data);
      } else {
        this._setRows();
      }
      this.getProcessing = false;
    });
    return promise;
  }
  //Para manejo de tabla
  async onSearch(pSearch:KeyboardEvent = null) {
    if (!pSearch || pSearch.key == 'Enter') {
      await this.get();
    }
  }
  onPage(pPage:number) {
    this.search.page = pPage;
    this.get();
  }
  onSort(pSort:string) {
    this.search.sort = pSort;
    this.get();
  }
  onTableSizeChange(pEvent:Event) { this.get(); }
  onActivate(pTable:DatatableComponent, pEvent) {
    if(pEvent.type == 'click' && pEvent.cellIndex != this.search.columnActions) {
      pTable.rowDetail.toggleExpandRow(pEvent.row);
    }
  }
  addRow(pRow:Currency) {
    if (!this.rows) { this.rows = []; }
    if (this.rows.indexOf(pRow) == -1) {
      this.rows.unshift(pRow);
      this.search.count += 1;
    }
  }
  delRow(pRow:Currency) {
    const index = this.rows.indexOf(pRow);
    if (index != -1) {
      this.rows.splice(index, 1);
      this.search.count -= 1;
    }
  }
}
