import { Injectable } from '@angular/core';
import { ApiRes } from '../interface/api-res';
import { CardIdType } from '../interface/cardid-type';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CardidTypeService {
  row:CardIdType;
  rows:CardIdType[]=[];
  rowsPerson:CardIdType[]=[];
  private _method:string;
  constructor(private _api:ApiService) { this.init(); }
  async init() {
    this._method = 'MerchantCardIdType';
    this.row = {
      IdCardIdType: 0,
      NameCardIdType: null,
      Regex: null,
      IdCountry: null,
    }
  }
  fetch() {
    let promise:Promise<ApiRes> = this._api.post(`${this._method}/Get`);
    promise.then((pApiRes:ApiRes) => {
      this.rows = pApiRes.data;
      this.rowsPerson = pApiRes.data;
      this.removeJuridico();
      //console.log(this.rows);
    });
    return promise;
  }
  removeJuridico(){
    this.rowsPerson = this.rowsPerson.filter(objeto => objeto.IdCardIdType !== 2);
  }
}