import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';
import { ApiRes, MerchantRequest, TableOptions } from '../interface';
import { ApiService } from './api.service';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { UserData, UserDataRow } from '../interface/user';
@Injectable({
  providedIn: 'root'
})
export class MerchantService {

  constructor() { }
}
@Injectable({
  providedIn: 'root'
})
export class MerchantRequestService {
  merchantResult: UserDataRow;
  delProcessing: boolean = false;
  editProcessing: boolean = false;
  addProcessing: boolean = false;
  getProcessing: boolean = false;
  search: TableOptions = {
    search: null,
    page: 1,
    count: 0,
    size: 2,
    sizes: [2, 10, 25, 50, 100],
    sort: 'IdMerchantRequest_desc',
    params: {
      IdMerchantRequest: null,
    },
    columnMode: ColumnMode,
    columnActions: 4//Inicia en cero
  };
  row: MerchantRequest;
  rows: MerchantRequest[];
  private _method: string = 'MerchantRequest';
  get _search() {
    let toReturn = {
      StartLimit: (this.search.page * this.search.size) - this.search.size + 1,
      EndLimit: this.search.page * this.search.size,
      Order: this.search.sort,
      Search: this.search.search,
      Type: this.search.Type,
    };
    Object.entries(this.search.params).forEach(([key, value]) => {
      toReturn[key] = value;
    });
    return toReturn;
  }
  constructor(
    private _api: ApiService,
    private _config: ConfigService,
  ) { this.init(); }
  init() { this._setRow(); }
  //Set Row y Rows
  private _setRow(pAppend: Boolean = false, pRow: MerchantRequest = {}) {
    if (pAppend) {
      this.addRow(pRow);
    } else {
      this.row = pRow;
    }
  }
  private _setRows(pAppend: Boolean = false, pData: MerchantRequest[] = null) {
    if (pAppend) {
      this.rows = [...this.rows, ...pData];
    } else {
      this.rows = pData;
    }
  }
  validateKinposValues(data: UserDataRow) {
    const promise: Promise<ApiRes> = this._api.post(`Merchant/VerifyUser`,
      {
        IdMerchant: data.idMerchant
      });
    promise.then((pApiRes: ApiRes) => {
      if (pApiRes.ok) {
        //console.log('Success-MerchantRequest', pApiRes.data);
      } else {
        //console.log('Error-MerchantRequest', pApiRes);
      }
    });
    return promise;
  }
  updateKinposValues(data: UserDataRow) {
    const promise: Promise<ApiRes> = this._api.post(`Merchant/Edit`,
      {
        IdMerchant: data.idMerchant,
        KinPosTerminalId: data.KinPosTerminalId,
        KinPosUsername: data.KinPosUsername,
        KinPosApiKey: data.KinPosApiKey,
        KinPosMerchantId: data.KinPosMerchantId,
        KinPosSecurityStamp: data.KinPosSecurityStamp,
        KinPosSecretKey: data.KinPosSecretKey
      });
    promise.then((pApiRes: ApiRes) => {
      if (pApiRes.ok) {


        //console.log('Success-MerchantRequest', pApiRes.data);
      } else {
        //console.log('Error-MerchantRequest', pApiRes);
      }
    });
    return promise;
  }
  //Obtención de datos
  get(IdMerchant: any) {
    this.getProcessing = true;
    let row = {
      IdMerchant: IdMerchant
    }
    const promise: Promise<ApiRes> = this._api.post(`MerchantBackOffice/Get`, row);
    promise.then((pApiRes: ApiRes) => {
      if (pApiRes.ok) {
        this.merchantResult = pApiRes.data;
        this.search.count = pApiRes.recordsTotal;

        //console.log('Success-MerchantRequest', pApiRes.data);
      } else {
        //console.log('Error-MerchantRequest', pApiRes);
        this._setRows();
      }
      this.getProcessing = false;
    });
    return promise;
  }
  //Para manejo de tabla
  async onSearch(pSearch: KeyboardEvent = null) {
    if (!pSearch || pSearch.key == 'Enter') {
      //     await this.get();
    }
  }
  onPage(pPage: number) {
    this.search.page = pPage;
    // this.get();
  }
  onSort(pSort: string) {
    this.search.sort = pSort;
    // this.get();
  }
  //  onTableSizeChange(pEvent:Event) { this.get(); }
  onActivate(pTable: DatatableComponent, pEvent) {
    if (pEvent.type == 'click' && pEvent.cellIndex != this.search.columnActions) {
      pTable.rowDetail.toggleExpandRow(pEvent.row);
    }
  }
  addRow(pRow: MerchantRequest) {
    if (!this.rows) { this.rows = []; }
    if (this.rows.indexOf(pRow) == -1) {
      this.rows.unshift(pRow);
      this.search.count += 1;
    }
  }
  delRow(pRow: MerchantRequest) {
    const index = this.rows.indexOf(pRow);
    if (index != -1) {
      this.rows.splice(index, 1);
      this.search.count -= 1;
    }
  }
  add() {
    this.addProcessing = true;
    this.row.IdMerchant = this._config.User.IdMerchant;
    let promise: Promise<ApiRes> = this._api.post(`${this._method}/Add`, this.row);
    promise.then((pApiRes: ApiRes) => {
      if (pApiRes.ok) {
        this.addProcessing = false;
        this.addRow(pApiRes.data);
      }
    });
    return promise;
  }
}
