import { Injectable } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { TableOptions, Terminal, ApiRes } from '../interface';
import { ApiService } from './api.service';
import { GlobalService } from './global.service';
import { UserListService } from './user.service';
import { UserData } from '../interface/user';

interface TerminalSearch {
  Search?        : string;
  StartLimit: number;
  EndLimit  : number;
  Count?       : number;
  Order?         : string;
  IdTerminal?         : string;
  IdBranch?         : string;
}

@Injectable({
  providedIn: 'root'
})
export class TerminalService {
  delProcessing: boolean = false;
  editProcessing: boolean = false;
  addProcessing: boolean = false;
  getProcessing: boolean = false;
  getDataProcessing: boolean;
  ColumnMode = ColumnMode;
  row:Terminal;
  rows:Terminal[]=[];
  private _method:string  = 'Terminal';;
  private _search:TerminalSearch;
  get action(): string { return this._action; }
  set action(pAction: string) {
    this._action = pAction;
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }
  private _action: string = 'List';
  search: TableOptions = {
      search: null,
      page  : 1,
      count : 0,
      size  : 10,
      sizes : [10, 25, 50, 100],
      sort  : 'IdTerminal_asc',
      params: {
        IdBranch: null,
        IdTerminal: null,
      },
      columnMode: ColumnMode,
      columnActions: (window.innerWidth < 960) ? 1 : 4//Inicia en cero
    };
  constructor(
    private _api:ApiService,
    private _global:GlobalService,
    private _providerUserList: UserListService,) { this.init(); }
    init() { this._initRow();}

  fetch(pAppend:boolean = false):Promise<ApiRes> {
    this._initRow();
    this.getProcessing = true;
    this._search = {
        IdTerminal : this.search.params.IdTerminal,
        IdBranch: this.search.params.IdBranch,
        Search        : this.search.search,
        StartLimit: (this.search.page * this.search.size) - this.search.size + 1,
        EndLimit  : this.search.page * this.search.size,
        Order         : this.search.sort,
      }
    const promise:Promise<ApiRes> = this._api.post(`${this._method}/Get`, this._search);
    promise.then((pApiRes:ApiRes) => {
      if (pApiRes.ok) {
        this.search.count = pApiRes.recordsTotal;
        this._addRows(pAppend, pApiRes.data);
      } else {
        this._initRows();
      }
      this.getProcessing = false;
    });
    return promise;
  }
  add() {
    this.addProcessing = true;
    const promise:Promise<ApiRes> = this._api.post(`${this._method}/Add`, this.row);
    promise.then((pApiRes:ApiRes) => {
      if (pApiRes.ok) {
        this.action = 'List';
        this.fetch();
      } else {
        this._global.showAlert("Error", pApiRes.info);
      }
      this.addProcessing = false;
    });
    return promise;
  }
  edit() {
    this.editProcessing = true;
    const promise:Promise<ApiRes> = this._api.post(`${this._method}/Edit`, this.row);
    promise.then((pApiRes:ApiRes) => {
      if (!pApiRes.ok) {
        this._global.showAlert("Error", pApiRes.info);
      } else {
        this.action = 'List';
        this.fetch();
      }
      this.editProcessing = false;
    });
    return promise;
  }
  del(pEdit:boolean = false) {
    this.delProcessing = true;
    const promise:Promise<ApiRes> = this._api.post(`${this._method}/Del`, this.row);
    promise.then((pApiRes:ApiRes) => {
      if (pApiRes.ok) {
        this.action = 'List';
        this.fetch();
      } else {
        this._global.showAlert("Error", pApiRes.info);
      }
      this.delProcessing = false;
    });
    return promise;
  }
  private async _addRows(pAppend:Boolean = false, pData:Terminal[] = null) {
    if (pAppend) {
      this.rows = [...this.rows, ...pData];
    } else {
      this.rows = pData;
    }
  }
  //Para manejo de tabla
  async onSearch(pSearch:KeyboardEvent = null) {
    if (!pSearch || pSearch.key == 'Enter') {
    await this.fetch();
    }
}
onPage(pPage:number) {
    this.search.page = pPage;
    this.fetch();
}
onSort(pSort:string) {
    this.search.sort = pSort;
    this.fetch();
}
onTableSizeChange(pEvent:Event) { this.fetch(); }
onActivate(pTable:DatatableComponent, pEvent) {
    if(pEvent.type == 'click' && pEvent.cellIndex != this.search.columnActions) {
    pTable.rowDetail.toggleExpandRow(pEvent.row);
    }
}
private _initRow() { this.row = {}; }
private _initRows() { this.rows = []; }
}