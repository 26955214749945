import { Component, OnInit } 		 from '@angular/core';

@Component({
  selector: 'skeleton',
  templateUrl: './skeleton.component.html',
  inputs: ['image', 'height', 'width'],
  styleUrls: ['./skeleton.component.css'],
})

export class SkeletonComponent implements OnInit {
  ngOnInit(): void {
  }
}
