
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../../../../service';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { UserListService } from '../../../../../service/user.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-charges',
  templateUrl: './business-list.component.html',
  styleUrls: ['./business-list.component.css'],

})
export class BusinessListComponent implements OnInit {
  @Output() crossOffSetAction = new EventEmitter<any>();

  ColumnMode = ColumnMode;
  // @ViewChild(DatatableComponent) table: DatatableComponent;
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  // closeResult: string;
  // visible: boolean = false;
  // visiblePaymentLink: boolean = false;
  // ColumnMode = ColumnMode;
  // expandedElement: PaymentLinks | null;
  // selection = new SelectionModel<PaymentLinks>(true, []);
  // url: string;
  // fileUrl: any;
  constructor(
    public translate: TranslateService,
    public config: ConfigService,
    public provider: UserListService,
    private router: Router
    ) {}

    async ngOnInit(): Promise<void> {
      //Listeners
      window.addEventListener('Merchant:fetch', () => { this.provider.fetch(); });
      await this.onFetch();
    }
    async onFetch() {
      this.provider.getDataProcessing = true;
      await this.provider.fetch().then(pApiRes => {
        this.provider.getDataProcessing = false;
      });
    }
    refresh() { window.dispatchEvent(new CustomEvent('Merchant:fetch')); }
    onSelectRed(row) {
      this.router.navigate(['/bo-businessdetail', row.IdMerchant]);
    }
    
    onSelectBlue(value) {
    }
    updateFilter(event) {
/*       const val = event.target.value.toLowerCase();
  
      // filter our data
      const temp = this.temp.filter(function (d) {
        return d.name.toLowerCase().indexOf(val) !== -1 || !val;
      });
  
      // update the rows
      this.rows = temp;
      // Whenever the filter changes, always go back to the first page
      this.table.offset = 0; */
    }





}
export interface PaymentLinks {
  idLink: number;
  link: string;
  cost: string;
  soldUnities: string;
  maxUnities: string;
  activeUntil: string;
  status: number;
  shareLink: string;
  userTransactions: Array<any>;
}
