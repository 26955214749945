import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../../../service';
import { UserListService } from '../../../../service/user.service';

@Component({
  selector: 'app-merchant',
  templateUrl: './merchant.component.html',
  styleUrls: ['./merchant.component.css']
})
export class MerchantComponent implements OnInit {

  constructor(
    public provider: UserListService,
    public config: ConfigService,
  ) { }

  ngOnInit(): void {
  }

}
