import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../../../../service';
import { DomSanitizer } from '@angular/platform-browser';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { UserListService } from '../../../../../service/user.service';
import { UserDataRow } from '../../../../../interface/user';


@Component({
  selector: 'app-charges',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css'],

})
export class UserListComponent implements OnInit {
 
  rows:UserDataRow[] = [];
  temp = [];
  columns = [{ prop: 'NameMerchant'}, { name: 'Estado' }, {name: 'Dirección'}, {name: 'Teléfono'}, {name: 'Correo eléctronico'}, {name: 'Fecha de nacimiento'}, {name: 'Actions'}];
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  closeResult: string;
  visible: boolean = false;
  visiblePaymentLink: boolean = false;
  ColumnMode = ColumnMode;
  expandedElement: PaymentLinks | null;
  selection = new SelectionModel<PaymentLinks>(true, []);
  url: string;
  fileUrl: any;
  constructor(



    public translate: TranslateService,
    public provider: UserListService,
    public configService: ConfigService
    ) {


    }
  async ngOnInit(): Promise<void> {
    await this.provider.getData();
   

    
  }
    onSelectRed(row) {
    }
    
    onSelectBlue(value) {
    }
    updateFilter(event) {
/*       const val = event.target.value.toLowerCase();
  
      // filter our data
      const temp = this.temp.filter(function (d) {
        return d.name.toLowerCase().indexOf(val) !== -1 || !val;
      });
  
      // update the rows
      this.rows = temp;
      // Whenever the filter changes, always go back to the first page
      this.table.offset = 0; */
    }





}
export interface PaymentLinks {
  idLink: number;
  link: string;
  cost: string;
  soldUnities: string;
  maxUnities: string;
  activeUntil: string;
  status: number;
  shareLink: string;
  userTransactions: Array<any>;
}
