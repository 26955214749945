import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ConfigService, HomeService } from '../../../../service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    public config: ConfigService,
    public provider: HomeService,
    private _router: Router,
  ) {
    this.provider.fetch();
   }

  ngOnInit(): void {
  }
  

}
