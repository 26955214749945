import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class FunctionService {
  constructor() { }
  showAlert(pParams: {
    Message: string;
    Success?: boolean;
  }) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      iconColor: 'white',
      customClass: {
        container: 'bg-dark text-white',
        popup: 'bg-dark text-white',
        header: 'bg-dark text-white',
        title: 'bg-dark text-white',
        closeButton: 'bg-dark text-white',
        icon: 'bg-dark text-white',
        image: 'bg-dark text-white',
        content: 'bg-dark text-white',
        htmlContainer: 'bg-dark text-white',
        input: 'bg-dark text-white',
        validationMessage: 'bg-dark text-white',
        actions: 'bg-dark text-white',
        confirmButton: 'bg-dark text-white',
        denyButton: 'bg-dark text-white',
        cancelButton: 'bg-dark text-white',
        loader: 'bg-dark text-white',
        footer: 'bg-dark text-white'
      },
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true
    });
    Toast.fire({
      icon: pParams.Success ? 'success' : 'error',
      title: pParams.Message,
    });
  }
}