import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { ApiRes } from '../interface';
import { GlobalService } from './global.service';
import { JoseService } from './jose.service';
import { StorageService } from './storage.service';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private _enableEncrypt: boolean = true;
  constructor(
    private _http: HttpClient,
    private _global: GlobalService,
    private _jose: JoseService,
    private _storage: StorageService,
  ) {

  }

  post(pEndPoint: string, pBody?: any): Promise<ApiRes> {
  
    let promise: Promise<ApiRes> = new Promise((fSuccess: Function) => {
      this._post(pEndPoint, pBody ? pBody : {}).toPromise()
        .then(async (pApiRes: ApiRes) => {
          if (pApiRes.ok && pEndPoint.endsWith('/Login')) {
            await this._storage.set('user', pApiRes.data);
          }
          if (pApiRes.ok && pEndPoint == 'Parameter/Get') {
            await this._storage.set('params', pApiRes.data);
          }
          if (pApiRes.data) { pApiRes.data = this._enableEncrypt ? JSON.parse(await this._jose.decrypt(pApiRes.data)) : pApiRes.data; }
          if (pApiRes.data?.sub) { pApiRes.data = pApiRes.data.sub; }
          if (pApiRes.token) { await this._storage.set('token', pApiRes.token); }
          if (pApiRes.expired) { window.dispatchEvent(new CustomEvent('login:logout')); }
          fSuccess(pApiRes);
        }).catch(pError => {
          if (pError.error.expired) { window.dispatchEvent(new CustomEvent('login:logout')); }
          fSuccess({ ok: false, info: this._global.words.PAGE_API_ERROR, data: pError });
        });
    });
    return promise;
  }
  private _post(pEndPoint: string, pBody?: any) {
    return this._http.post(`${environment.urlApi}${pEndPoint}`, pBody ? pBody : {});
  }
  get(pEndPoint: string) {
    const promise: Promise<ApiRes> = new Promise((fSuccess: Function) => {
      this._get(pEndPoint).toPromise()
        .then((pApiRes: any) => {
          if (pApiRes.data) { pApiRes.data = this._enableEncrypt ? this._jose.decrypt(pApiRes.data) : pApiRes.data; }
          if (pApiRes.data?.sub) { pApiRes.data = pApiRes.data.sub; }
          fSuccess(pApiRes);
        }).catch(pError => {
          fSuccess({ ok: false, info: this._global.words.PAGE_API_ERROR, data: pError });
        });
    });
    return promise;
  }
  private _get(pEndPoint: string, pParams?: any, pReqOpts?: any) {
    if (!pReqOpts) {
      pReqOpts = {
        params: new HttpParams()
      };
    }
    // Support easy query params for GET requests
    if (pParams) {
      pReqOpts.params = new HttpParams();
      for (let k in pParams) {
        pReqOpts.params = pReqOpts.params.set(k, pParams[k]);
      }
    }
    return this._http.get(`${environment.urlApi}${pEndPoint}`, pReqOpts);
  }
}

export interface ApiResTest {
  status           : string;
  message         : string;
  data         : any;
  comment?: string;
}