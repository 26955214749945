// Core Module
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as global from './config/globals';

// Main Component
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarRightComponent } from './components/sidebar-right/sidebar-right.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { PanelComponent } from './components/panel/panel.component';
import { FloatSubMenuComponent } from './components/float-sub-menu/float-sub-menu.component';


// Component Module
import { AgmCoreModule } from '@agm/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TrendModule } from 'ngx-trend';
import { HighlightJsModule } from 'ngx-highlight-js';
import { CountdownModule } from 'ngx-countdown';
import { NgChartjsModule } from 'ng-chartjs';
import { TagInputModule } from 'ngx-chips';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NvD3Module } from 'ng2-nvd3';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import 'd3';
import 'nvd3';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
//Translate
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
//Necesario para solucionar error 404
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
//Providers
import {
  GlobalService, ApiService, ConfigService, JoseService,
  LoginTokenService, LoginService, LoginDeviceService, StorageService,
  UserService,
  FunctionService, MerchantRequestService, CurrencyService, HomeService
} from './service';
//Interceptores
import { ApiInterceptor } from './interceptor';
// Guards
import { UserGuard } from './guard';
// Pages
import { TestComponent } from './page/public/test/test.component';
import { LoginComponent } from './page/public/login/login.component';
//Plugins
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AccessComponent } from './page/public/access/access.component';
import { HomeComponent } from './page/private/backoffice/home/home.component';
import { ChargesComponent } from './page/private/charges/charges.component'
import { MaterialModuleImport } from './material.module';
import { SkeletonComponent } from './components/skeleton/skeleton.component';
import { BoLoginComponent } from './page/public/bo-login/bo-login.component';
import { UserListComponent } from './page/private/backoffice/users/user-list/user-list.component';
import { BusinessListComponent } from './page/private/backoffice/business/business-list/business-list.component';
import { UserDetailComponent } from './page/private/backoffice/users/user-detail/user-detail.component';
import { BusinessDetailComponent } from './page/private/backoffice/business/business-detail/business-detail.component';
import { CreateUserComponent } from './page/private/backoffice/users/create-user/create-user.component';
import { MerchantListComponent } from './page/private/backoffice/merchant/merchant-list/merchant-list.component';
import { MerchantFormComponent } from './page/private/backoffice/merchant/merchant-form/merchant-form.component';
import { MerchantComponent } from './page/private/backoffice/merchant/merchant.component';
import { BranchComponent } from './page/private/backoffice/branch/branch.component';
import { BranchFormComponent } from './page/private/backoffice/branch/branch-form/branch-form.component';
import { BranchListComponent } from './page/private/backoffice/branch/branch-list/branch-list.component';
import { TerminalComponent } from './page/private/backoffice/terminal/terminal.component';
import { TerminalListComponent } from './page/private/backoffice/terminal/terminal-list/terminal-list.component';
import { TerminalFormComponent } from './page/private/backoffice/terminal/terminal-form/terminal-form.component';
import { MerchantBackOfficeComponent } from './page/private/backoffice/merchant-back-office/merchant-back-office.component';
import { MerchantBackOfficeListComponent } from './page/private/backoffice/merchant-back-office/merchant-back-office-list/merchant-back-office-list.component';
import { MerchantBackOfficeFormComponent } from './page/private/backoffice/merchant-back-office/merchant-back-office-form/merchant-back-office-form.component';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    SidebarRightComponent,
    TopMenuComponent,
    FooterComponent,
    PanelComponent,
    FloatSubMenuComponent,
    TestComponent,
    LoginComponent,
    AccessComponent,
    HomeComponent,
    BusinessDetailComponent,
    ChargesComponent,
    SkeletonComponent,
    BoLoginComponent,
    UserListComponent,
    UserDetailComponent,
    BusinessListComponent,
    CreateUserComponent,
    MerchantListComponent,
    MerchantFormComponent,
    MerchantComponent,
    BranchComponent,
    BranchFormComponent,
    BranchListComponent,
    TerminalComponent,
    TerminalListComponent,
    TerminalFormComponent,
    MerchantBackOfficeComponent,
    MerchantBackOfficeListComponent,
    MerchantBackOfficeFormComponent,
  ],
  imports: [
    AppRoutingModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyC5gJ5x8Yw7qP_DqvNq3IdZi2WUSiDjskk' }),
    BrowserAnimationsModule,
    BrowserModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    CountdownModule,
    NgChartjsModule,
    FullCalendarModule,
    FormsModule,
    HighlightJsModule,
    LoadingBarRouterModule,
    NgbModule,
    NgxChartsModule,
    NgxDatatableModule,
    NgxDaterangepickerMd.forRoot(),
    PerfectScrollbarModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot(),
    TagInputModule,
    TrendModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    CommonModule,
    NgxMaskModule.forRoot(),
    NvD3Module,
    MaterialModuleImport
  ],
  providers: [Title,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    GlobalService, ApiService, ConfigService, JoseService, UserGuard,
    LoginTokenService, LoginService, LoginDeviceService, StorageService,
    FunctionService, MerchantRequestService, CurrencyService, UserService, HomeService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(
    private _router: Router,
    private _titleService: Title,
    private _route: ActivatedRoute,
    private _storage: StorageService,
    private _jose: JoseService,
  ) {
    this._router.events.subscribe(async (e) => {
      var title = '';
      const paramsEncrypted = await this._storage.get('params');
      if (paramsEncrypted) {
        const paramsDecrypted = await this._jose.decrypt(paramsEncrypted);
        const params = JSON.parse(paramsDecrypted);
        title = params.sub.NameCompany.Value;
      }
      if (e instanceof NavigationEnd) {
        title = `${title} | ${this._route.snapshot.firstChild.data['title']}`;
        this._titleService.setTitle(title);
      }
    });
  }
}