import { CreateUserComponent } from './page/private/backoffice/users/create-user/create-user.component';
import { UserListComponent } from './page/private/backoffice/users/user-list/user-list.component';
import { NgModule } from '@angular/core';
import { UserGuard } from './guard';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TestComponent } from './page/public/test/test.component';
import { LoginComponent } from './page/public/login/login.component';
import { AccessComponent } from './page/public/access/access.component';
import { HomeComponent } from './page/private/backoffice/home/home.component';
import { ChargesComponent } from './page/private/charges/charges.component';
import { BoLoginComponent } from './page/public/bo-login/bo-login.component';
import { UserDetailComponent } from './page/private/backoffice/users/user-detail/user-detail.component';
import { BusinessListComponent } from './page/private/backoffice/business/business-list/business-list.component';
import { BusinessDetailComponent } from './page/private/backoffice/business/business-detail/business-detail.component';
import { MerchantListComponent } from './page/private/backoffice/merchant/merchant-list/merchant-list.component';
import { MerchantComponent } from './page/private/backoffice/merchant/merchant.component';
import { BranchComponent } from './page/private/backoffice/branch/branch.component';
import { TerminalComponent } from './page/private/backoffice/terminal/terminal.component';
import { MerchantBackOfficeComponent } from './page/private/backoffice/merchant-back-office/merchant-back-office.component';
import { MerchantBackOfficeGuard } from './guard/merchant-back-office.guard';

const routes: Routes = [
  { path: '', redirectTo: '/bo-login', pathMatch: 'full' },
  { path: 'test', component: TestComponent, data: { title: 'TEST' } },
  { path: 'login', component: LoginComponent, data: { title: 'LOGIN' } },
  { path: 'access', component: AccessComponent, data: { title: 'ACCESS' } },
  { path: 'home', component: HomeComponent, data: { title: 'HOME' }, canActivate: [UserGuard] },
  { path: 'charges', component: ChargesComponent, data: { title: 'Charges' } },
  { path: 'bo-login', component: BoLoginComponent, data: { title: 'LOGIN' } },
  { path: 'bo-userlist', component: UserListComponent, data: { title: 'Usuarios' }, canActivate: [UserGuard] },
  { path: 'bo-businesslist', component: BusinessListComponent, data: { title: 'Negocios' }, canActivate: [UserGuard] },
  { path: 'bo-userdetail/:id', component: UserDetailComponent, data: { title: 'Detalle usuario' }, canActivate: [UserGuard] },
  { path: 'bo-businessdetail/:IdMerchant', component: BusinessDetailComponent, data: { title: 'Detalle negocio' }, canActivate: [UserGuard] },
  { path: 'bo-create-user', component: CreateUserComponent, data: { title: 'Crear usuario' }, canActivate: [UserGuard] },
  { path: 'bo-merchantlist', component: MerchantListComponent, data: { title: 'Merchant List' }, canActivate: [UserGuard] },
  { path: 'bo-merchant', component: MerchantComponent, data: { title: 'Merchant' }, canActivate: [UserGuard] },
  { path: 'bo-merchant-back-office', component: MerchantBackOfficeComponent, data: { title: 'MerchantBackOffice' }, canActivate: [UserGuard, MerchantBackOfficeGuard] },
  { path: 'bo-branch/:IdMerchant/:NameMerchant', component: BranchComponent, data: { title: 'Branch Office' }, canActivate: [UserGuard] },
  { path: 'bo-terminal/:IdBranch/:NameBranch/:IdMerchant/:NameMerchant', component: TerminalComponent, data: { title: 'Terminal' }, canActivate: [UserGuard] },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: []
})


export class AppRoutingModule { }
