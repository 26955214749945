import { HostListener, Injectable } from '@angular/core';
import { Menu } from '../interface/menu';
import { JoseService } from './jose.service';
import { LoginDeviceService } from './login.service';
import { ParameterService } from './parameter.service';
import { StorageService } from './storage.service';
@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  started:boolean = false;
  row:any;
  language:string;
  //Config
  version:string;
  LongSize:boolean;
  //Menu
  MenuRow:Menu;
  //Login
  User:any;
  //Eventos
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.LongSize = window.innerWidth < 960 ? false : true;
  }
  constructor(
    private _jose: JoseService,
    private _loginDevice: LoginDeviceService,
    private _parameter: ParameterService,
    private _storage: StorageService,
  ) {
    this.version = 'PB&BO-1.0.0';
    this.init();
  }
  async init() {
    this.LongSize = window.innerWidth < 960 ? false : true;
    const uuidApp = await this._storage.get('uuidApp');
    const user = await this._storage.get('user');
    if (!uuidApp) {
      await this._loginDevice.init();
      await this._loginDevice.addEdit(this.version);
    }
    if (user) {
      this.User = JSON.parse(await this._jose.decrypt(user)).sub;
    }
    this.row = (await this._parameter.fetch()).data;
    this.started = true;
  }
  del() {
    this._storage.del('uuidApp');
    this._storage.del('params');
    this._storage.del('user');
  }
  public async setUser() {
    let user:any = await this._storage.get('user');
    if (user) { user = JSON.parse(await this._jose.decrypt(user)).sub; }
    if (user) {
      
      this.User = {
        User    : user.User,
        Type    : user.Type,
        uuid    : user.Token,
        Name    : user.Name,
        Phone   : user.Phone,
        Email   : user.Email,
        UrlImage: user.UrlImage,
        IdMerchant: user.MerchantRow.IdMerchant,
      }
      // if (pPath && this.User?.Menu) {
      //   //Obtener pagina actual
      //   this.user?.Menu.forEach(async (pRow) => {
      //     if (pRow.submenu) {
      //       pRow.submenu.forEach(async (pSubRow) => {
      //         if (pSubRow.url == pPath) { this.MenuRow = pSubRow; }
      //       });
      //     }
      //   });
      // }
    }
  }
  public async getUser() {
    let user:any = await this._storage.get('user');
    if (user) { user = JSON.parse(await this._jose.decrypt(user)).sub; }
    if (user) {
      return user;
    }
    return null;
  }
}