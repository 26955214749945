import { Injectable } from '@angular/core';
import {JWK, JWE, parse} from 'node-jose';
import { StorageService } from './storage.service';
@Injectable({
  providedIn: 'root'
})
export class JoseService {
  constructor(private _storage: StorageService) { }
  async encrypt(pRaw, pFormat = 'compact', pContentAlg = "A128CBC-HS256", pAlg = "RSA1_5") {
    const _publicKey = await this._storage.get('publicKey');
    let publicKey = await JWK.asKey(_publicKey, "pem");
    const buffer = Buffer.from(JSON.stringify(pRaw))
    const encrypted = await JWE.createEncrypt({ format: pFormat, contentAlg: pContentAlg, fields: { alg: pAlg } }, publicKey).update(buffer).final();
    return encrypted;
  }
  async decrypt(pEncrypted) {
    const _privateKey = await this._storage.get('privateKey');
    let keystore = JWK.createKeyStore();
    await keystore.add(await JWK.asKey(_privateKey, "pem"));
    let outPut = parse.compact(pEncrypted);
    let decryptedVal = await outPut.perform(keystore);
    let claims = Buffer.from(decryptedVal.plaintext).toString();
    return claims;
  }
}
