import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../service';
import { from } from 'rxjs';
import { Device } from '@capacitor/device';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(
    private _storage:StorageService,
    private _translate: TranslateService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // convert promise to observable using 'from' operator
    return from(this._handle(request, next));
  }
  private async _handle(request: HttpRequest<any>, next: HttpHandler) {
    const device = await Device.getId();
    const uuidApp = device.uuid;
    let language = this._translate.getBrowserLang();
    if (!(language && language.match(/es|en/))) { language = 'en'; }
    const token = await this._storage.get('token');
    const body = await request.body;
    if (token) {
        request = request.clone({
          setHeaders: {
            authorization: `Bearer ${token}`,
            language: language ?? 'en',
            uuidApp: uuidApp,
          },
          body
        });
      } else {
        request = request.clone({
          setHeaders: {
            language: language ?? 'en',
            uuidApp: uuidApp,
          },
          body
        });
      }
    // Important: Note the .toPromise()
    return next.handle(request).toPromise()
  }
}
