import { ApiRes } from './../interface/api-res';
import { userAdd, UserData, UserDataRow, UserList } from './../interface/user';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { stringify } from 'querystring';
import { TableOptions } from '../interface';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { GlobalService } from './global.service';
import { Step } from '../interface/step';

interface UserListSearch {
    Search?        : string;
    StartLimit: number;
    EndLimit  : number;
    Count?       : number;
    Order?         : string;
    CardId?         : string;
    IdMerchant? :number;
    IdLoginRole? :number,
  }
@Injectable({
    providedIn: 'root'
})

export class UserListService {
    IdLoginRol = 2;
    delProcessing : boolean = false;
    editProcessing: boolean = false;
    addProcessing : boolean = false;
    getProcessing : boolean = false;
    row: UserData;
    rows: UserData[];
    steps: Step[] = [
      { stepIndex: 1, isComplete: false },
      { stepIndex: 2, isComplete: false },
      { stepIndex: 3, isComplete: false }
    ];
    businessProfessionalSelected = 1;
    currentStep: Step = { stepIndex: 1, isComplete: false };
    getDataProcessing: boolean;
    error: string;
    get action(): string { return this._action; }
    set action(pAction: string) {
      this._action = pAction;
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
    private _action: string = 'List';
    private _method: string = 'MerchantBackOffice';
    private _search:UserListSearch;
    search: TableOptions = {
        search: null,
        page  : 1,
        count : 0,
        size  : 10,
        sizes : [10, 25, 50, 100],
        sort  : 'IdMerchand_desc',
        params: {
          IdMerchant: null,
          IdLoginRole:2
        },
        columnMode: ColumnMode,
        columnActions: (window.innerWidth < 960) ? 1 : 4//Inicia en cero
      };
    constructor(
        private _api: ApiService,
        private _global:GlobalService,
    ) { this.init(); }

    init() { this._initRow();}
    cleanRow(){
      this.row = {
      RowNum:              null,
      uuid:              null,
      Phone:             null,
      Email:             null,
      Password:      null,
      LastLogin:         null,
      IdProvince:        null,
      NameProvince:      null,
      IdCanton:          null,
      NameCanton:        null,
      IdDistrict:        null,
      NameDistrict:      null,
      IdNeighborhood:    null,
      NameNeighborhood:  null,
      Qr:                null,
      QrExt:             null,
      IdCountry:         null,
      NameCountry:       null,
      LastNameMerchant:  null,
      SendedSinpeMovil:  null,
      PhoneSinpeMovil:   null,
      ToPMerchant: null,
      SendedOpenBanking: null,
      KinPosTerminalId:  null,
      KinPosUsername:    null,
      KinPosApiKey:      null,
      KinPosMerchantId:  null, 
      KinPosSecurityStamp: null,
      KinPosSecretKey:   null,
      uuidApp:           null,
      Status:            null,
      Active:            null,
      Menu:              null,
      RoleImage:         null,
      RoleImageAdd:      null,
      IdMerchant:        null,
      IdLogin:           null,
      NameMerchant:      null,
      IdCardIdType:      null,
      CardId:            null,
      IdLocation:        null,
      Address:           null,
      ShoppingLimit:     null,
      IdLoginRole:       null,
      BusinessName:      null,
      ProductBusinessDescription: null,
      Gender: null,
      ManagerCardIdType: null,
      ManagerCardId: null,
      ManagerName: null,
      ManagerLastName: null,
      ManagerCardIdExpiration: null,
      ManagerPhone: null,
      ManagerEmail: null,
      IdComercialActivity: null,
      User: null,
      BiometricLogin: null,
      CardIdExpiration: null,
      DateBirth: null,
      IdMaritalStatus: null,
      IdProfession: null,
      IdOccupation: null,
      MonthsBusinessHistory: null,
      BusinessAddress: null,
      IdBranch: null,
      IdTerminal: null,
    };
    
      let initSearch : TableOptions = {
        search: null,
        page  : 1,
        count : 0,
        size  : 10,
        sizes : [10, 25, 50, 100],
        sort  : 'IdMerchand_desc',
        params: {
          IdMerchant: null,
          IdLoginRole:2
        },
        columnMode: ColumnMode,
        columnActions: (window.innerWidth < 960) ? 1 : 4//Inicia en cero
      };
      this.search = initSearch;
    }
    
    initSearch(pIdLoginRole:number){
      let initSearch : TableOptions = {
        search: null,
        page  : 1,
        count : 0,
        size  : 10,
        sizes : [10, 25, 50, 100],
        sort  : 'IdMerchand_desc',
        params: {
          IdMerchant: null,
          IdLoginRole:pIdLoginRole
        },
        columnMode: ColumnMode,
        columnActions: (window.innerWidth < 960) ? 1 : 4//Inicia en cero
      };
      this.search = initSearch;
    }
    // getBusiness() {
    //     const result: Promise<ApiRes> = this._api.post(`${this._method}/Get`)
    //     result.then(res => {
    //         this.row = res.data.filter(q=> q.IdLoginRole == 2);
    //     })
    // }
    async syncFetch(){
        this.getDataProcessing = true;
        await this.fetch().then(pApiRes => {
            this.getDataProcessing = false;
        });
    }
    
    fetch(pAppend:boolean = false):Promise<ApiRes> {
      this._initRow();
      this.currentStep.stepIndex=1;
      this.getProcessing = true;
      this._search = {
        Search        : this.search.search,
        StartLimit: (this.search.page * this.search.size) - this.search.size + 1,
        EndLimit  : this.search.page * this.search.size,
        Order         : this.search.sort,
        IdLoginRole: this.IdLoginRol,
      }
      const promise:Promise<ApiRes> = this._api.post(`${this._method}/Get`, this.search.search == 'IdMerchant' ? this.search.params: this._search);
      promise.then((pApiRes:ApiRes) => {
        if (pApiRes.ok) {
          this.search.count = pApiRes.recordsTotal;
          this._addRows(pAppend, pApiRes.data);
        } else {
          this._initRows();
        }
        this.getProcessing = false;
      });
      return promise;
    }
    trimString(input: string | null | undefined): string | null {
      if (input) {
          return input.trim();
      }
      return null;
  }
    add() {
      this.row.KinPosApiKey = this.trimString(this.row.KinPosApiKey);
      this.row.KinPosMerchantId = this.trimString(this.row.KinPosMerchantId);
      this.row.KinPosSecretKey = this.trimString(this.row.KinPosSecretKey);
      this.row.KinPosSecurityStamp = this.trimString(this.row.KinPosSecurityStamp);
      this.row.KinPosTerminalId = this.trimString(this.row.KinPosTerminalId);
      this.row.KinPosUsername = this.trimString(this.row.KinPosUsername);
      this.row.ToPMerchant = this.trimString(this.row.ToPMerchant);
      if (this.row.IdLoginRole != 4 && this.row.IdLoginRole != 6) {
        this.row.IdLoginRole = 2;
      }
      if(this.businessProfessionalSelected==2){
        this.row.IdCardIdType = 2;
      }
      if(this.row.Phone.length == 8){
        this.row.Phone = "+506" + this.row.Phone;
      }
      if (this.row.IdLoginRole == 2) {
        // if(this.row.PhoneSinpeMovil.length == 8){
        //   this.row.PhoneSinpeMovil = "+506" + this.row.PhoneSinpeMovil;
        // }
        this.row.User = this.row.Phone;
        if(this.businessProfessionalSelected==2){
          this.row.NameMerchant = this.row.BusinessName;
          if(this.row.ManagerPhone.length ==8){
            this.row.ManagerPhone = "+506" + this.row.ManagerPhone;
          }
        }
      }
      if (this.row.IdLoginRole == 6) {
        this.row.User = this.row.Phone;
      }
      if (this.row.IdLoginRole == 4) {
        this.row.User = this.row.Email;
      }
      
      
      
      this.row.BiometricLogin = true;
      this.addProcessing = true;
      const promise:Promise<ApiRes> = this._api.post(`${this._method}/Add`, this.row);
      promise.then((pApiRes:ApiRes) => {
        if (pApiRes.ok) {
          this.action = 'List';
          this.fetch();
        } else {
          //this._global.showAlert("Error", pApiRes.info);
          this.errorhandle(pApiRes);
        }
        this.addProcessing = false;
      });
      return promise;
    }
    // Función para validar si es un arreglo de strings
    isStringArray(objeto: any): objeto is string[] {
      if (Array.isArray(objeto)) {
        return objeto.every(elemento => typeof elemento === 'string');
      }
      return false;
    }
    errorhandle(pApiRes:ApiRes) {
      if (pApiRes.data && typeof pApiRes.data === 'object' && 'message' in pApiRes.data) {
        // Accede al atributo 'message'
        //console.log(pApiRes.data);
        let message = pApiRes.data.message;

        // Expresión regular para buscar un patrón que empiece con un '(' seguido de cualquier 
        // caracter (uno o más veces) y que termine con ')'
        let regex = /\((.+?)\)/;

        // Busca el patrón en el texto
        let resultado = message.match(regex);

        // Verifica si se encontró el patrón y muestra el resultado
        if (resultado && resultado[1]) {
          //console.log('Valor encontrado entre paréntesis:', resultado[1]);
          this._global.showAlert("Error", pApiRes.info + ". Valor con problemas:" + resultado[1]);
        } else {
          this._global.showAlert("Error", pApiRes.info);
        }
      } else {
        //El atributo "message" no existe o variableAny no es un objeto
        // Verificar si objetoAny puede ser convertido a un arreglo de strings
        if (this.isStringArray(pApiRes.data)) {
          // Concatenar los elementos en una sola variable
          let concatenado = pApiRes.data.join(' ');
          this._global.showAlert("Error", pApiRes.info + ". Valor(es) con problemas: " + concatenado);
          //console.log('Resultado concatenado:', concatenado);
        } else {
          this._global.showAlert("Error", pApiRes.info);
          //console.log('El objeto no es un arreglo de strings.');
        }
      }
    }
    edit() {
      this.editProcessing = true;
      if(this.row.Phone.length == 8){
        this.row.Phone = "+506" + this.row.Phone;
      }
      if (this.row.IdLoginRole == 2) {
        if(this.businessProfessionalSelected==2){
          if(this.row.ManagerPhone.length ==8){
            this.row.ManagerPhone = "+506" + this.row.ManagerPhone;
          }
        }
      }
      const promise:Promise<ApiRes> = this._api.post(`${this._method}/Edit`, this.row);
      promise.then((pApiRes:ApiRes) => {
        if (!pApiRes.ok) {
          this.errorhandle(pApiRes);
        } else {
          this.action = 'List';
          this.init
          this.fetch();
        }
        this.editProcessing = false;
      });
      return promise;
    }
    del(pEdit:boolean = false) {
      this.delProcessing = true;
      const promise:Promise<ApiRes> = this._api.post(`${this._method}/Del`, this.row);
      promise.then((pApiRes:ApiRes) => {
        if (pApiRes.ok) {
          this.action = 'List';
          this.fetch();
        } else {
          this._global.showAlert("Error", pApiRes.info);
        }
        this.delProcessing = false;
      });
      return promise;
    }
    getData() {
        const result: Promise<ApiRes> = this._api.post(`${this._method}/Get`)
        result.then(res => {
            this.row = res.data.filter(q=> q.IdLoginRole == 1);
        })
    }
    private _addRows(pAppend:Boolean = false, pData:UserData[] = null) {
        if (pAppend) {
          this.rows = [...this.rows, ...pData];
        } else {
          this.rows = pData;
        }
      }
    //Para manejo de tabla
    async onSearch(pSearch:KeyboardEvent = null) {
        if (!pSearch || pSearch.key == 'Enter') {
        await this.fetch();
        }
    }
    onPage(pPage:number) {
        this.search.page = pPage;
        this.fetch();
    }
    onSort(pSort:string) {
        this.search.sort = pSort;
        this.fetch();
    }
    onTableSizeChange(pEvent:Event) { this.fetch(); }
    onActivate(pTable:DatatableComponent, pEvent) {
        if(pEvent.type == 'click' && pEvent.cellIndex != this.search.columnActions) {
        pTable.rowDetail.toggleExpandRow(pEvent.row);
        }
    }
    private _initRow() { this.row = {};}
    private _initRows() { this.rows = []; }
}
@Injectable({
    providedIn: 'root'
})
export class UserService {
    row: userAdd;
    loginProcessing: boolean;
    error: string;
    private _method: string;
    constructor(
        private _api: ApiService,
    ) {
        this._method = 'MerchantBackOffice';
        this.init();

    }
    init() {
        this.row = {
            NameMerchant: null,
            LastNameMerchant: null,
            IdCardIdType: '1',
            CardId: null,
            IdLocation: '3758',
            Address: null,
            User: null,
            Gender: 'M',
            Password: null,
            Phone: null,
            ShoppingLimit: '1000',
            Email: null,
            IdLoginRole: '2',  //1: backoffice|2:bussiness|3:Customer
            BiometricLogin: '1'
        }
    }
    async newUser() {
        this.loginProcessing = true;
        this.row.Phone = `+506${this.row.Phone}`;
        //this.row.PhoneSinpeMovil = `+506${this.row.PhoneSinpeMovil}`;
        this.row.User = this.row.Phone;
        const response = await this._api.post(`${this._method}/Add`, this.row);
        this.loginProcessing = false;
            this.error =  !response.ok ? `${response.info} ${response.data.join(', ')}`: 'yes';
        
    }
    
    
}