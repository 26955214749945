import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService, CurrencyService, FunctionService, MerchantRequestService } from '../../../../../service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-charges',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('600ms ease-in-out', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('600ms ease-in-out', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class UserDetailComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<PaymentLinks>(ELEMENT_DATA);
  closeResult: string;
  visible: boolean = false;
  visiblePaymentLink: boolean = false;
  columnsToDisplay = ['link', 'cost', 'soldUnities', 'maxUnities', 'activeUntil', 'status', 'shareLink'];
  columnsToShow = [
    {
      header: 'TABLE_H_LINK',
      column: 'link'
    },
    {
      header: 'TABLE_COST',
      column: 'cost'
    },
    {
      header: 'TABLE_SOLD_UNITIES',
      column: 'soldUnities'
    },
    {
      header: 'TABLE_MAX_UNITIES',
      column: 'maxUnities'
    },
    {
      header: 'TABLE_ACTIVE_UNTIL',
      column: 'activeUntil'
    },
    {
      header: 'TABLE_STATUS',
      column: 'status'
    },
    {
      header: 'TABLE_SHARED_LINK',
      column: 'shareLink'
    }];
  expandedElement: PaymentLinks | null;
  selection = new SelectionModel<PaymentLinks>(true, []);
  url: string;
  fileUrl: any;
  businessId: string;
  constructor(
    private modalService: NgbModal,
    private merchantService: MerchantRequestService,
    private currencyService: CurrencyService,
    private functionService: FunctionService,
    public translate: TranslateService,
    private sanitizer: DomSanitizer,
    public configService: ConfigService,
    private route: ActivatedRoute
    ) {
      this.currencyService.get();
   //   this.merchantService.get();
      this.route.params.subscribe( params => {
        this.businessId = params.id;
      });
    }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
 
    this.merchantService.search.Type = 'LP';
    this.merchantService.search.Type = '';
  }

  async show() {
    let apiResp = await this.merchantService.add();
    if (apiResp.ok) {
      this.url = apiResp.data.Url;
      this.buildURLDownload();
      this.visible = !this.visible;
    } else {
      this.translate.get('SERVER_ERROR').subscribe(
        (pTranslation) => { 
          this.functionService.showAlert(pTranslation);
        }
      );
    }
  }

  showPaymentLink() {
    this.visiblePaymentLink = !this.visiblePaymentLink;
  }

  backPaymentLink () {
    this.visiblePaymentLink = !this.visiblePaymentLink;
  }

  getSelectedCurrency() {
    return this.currencyService.rows.find(item => item.IdCurrency == this.merchantService.row.IdCurrency);
  }

  buildURLDownload() {
    fetch(this.url)
    .then(res => res.blob()) // Gets the response and returns it as a blob
    .then(blob => {
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PaymentLinks): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.idLink + 1}`;
  }

  open(content , size) {
    this.modalService.open(content, { windowClass: 'modal-pos-item', size: size }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
export interface PaymentLinks {
  idLink: number;
  link: string;
  cost: string;
  soldUnities: string;
  maxUnities: string;
  activeUntil: string;
  status: number;
  shareLink: string;
  userTransactions: Array<any>;
}

const ELEMENT_DATA: PaymentLinks[] = [
  {
    idLink: 1,
    link: 'www.google.com',
    cost: '1.0079',
    soldUnities: '1.0079',
    maxUnities: '1.0079',
    activeUntil: '10/10/2020',
    status: 1,
    shareLink: 'www.google.com',
    userTransactions: [
      { userName: 'user1', amount: '1.0079', paymentMethod: 'cash', status: 1 },
      { userName: 'user2', amount: '1.0079', paymentMethod: 'card', status: 2 },
      { userName: 'user3', amount: '1.0079', paymentMethod: 'cash', status: 3 },
      { userName: 'user4', amount: '1.0079', paymentMethod: 'cash', status: 4 },
    ]
  },
  {
    idLink: 2,
    link: 'www.google.com',
    cost: '1.0079',
    soldUnities: '1.0079',
    maxUnities: '1.0079',
    activeUntil: '10/10/2020',
    status: 0,
    shareLink: 'www.google.com',
    userTransactions: [
      { userName: 'user1', amount: '1.0079', paymentMethod: 'cash', status: 1 },
      { userName: 'user2', amount: '1.0079', paymentMethod: 'card', status: 2 },
      { userName: 'user3', amount: '1.0079', paymentMethod: 'cash', status: 3 },
      { userName: 'user4', amount: '1.0079', paymentMethod: 'cash', status: 4 },
    ]
  },
  {
    idLink: 3,
    link: 'www.google.com',
    cost: '1.0079',
    soldUnities: '1.0079',
    maxUnities: '1.0079',
    activeUntil: '10/10/2020',
    status: 1,
    shareLink: 'www.google.com',
    userTransactions: [
      { userName: 'user1', amount: '1.0079', paymentMethod: 'cash', status: 1 },
      { userName: 'user2', amount: '1.0079', paymentMethod: 'card', status: 2 },
      { userName: 'user3', amount: '1.0079', paymentMethod: 'cash', status: 3 },
      { userName: 'user4', amount: '1.0079', paymentMethod: 'cash', status: 4 },
    ]
  },
  {
    idLink: 4,
    link: 'www.google.com',
    cost: '1.0079',
    soldUnities: '1.0079',
    maxUnities: '1.0079',
    activeUntil: '10/10/2020',
    status: 0,
    shareLink: 'www.google.com',
    userTransactions: [
      { userName: 'user1', amount: '1.0079', paymentMethod: 'cash', status: 1 },
      { userName: 'user2', amount: '1.0079', paymentMethod: 'card', status: 2 },
      { userName: 'user3', amount: '1.0079', paymentMethod: 'cash', status: 3 },
      { userName: 'user4', amount: '1.0079', paymentMethod: 'cash', status: 4 },
    ]
  },
  {
    idLink: 5,
    link: 'www.google.com',
    cost: '1.0079',
    soldUnities: '1.0079',
    maxUnities: '1.0079',
    activeUntil: '10/10/2020',
    status: 1,
    shareLink: 'www.google.com',
    userTransactions: [
      { userName: 'user1', amount: '1.0079', paymentMethod: 'cash', status: 1 },
      { userName: 'user2', amount: '1.0079', paymentMethod: 'card', status: 2 },
      { userName: 'user3', amount: '1.0079', paymentMethod: 'cash', status: 3 },
      { userName: 'user4', amount: '1.0079', paymentMethod: 'cash', status: 4 },
    ]
  },
  {
    idLink: 6,
    link: 'www.google.com',
    cost: '1.0079',
    soldUnities: '1.0079',
    maxUnities: '1.0079',
    activeUntil: '10/10/2020',
    status: 1,
    shareLink: 'www.google.com',
    userTransactions: [
      { userName: 'user1', amount: '1.0079', paymentMethod: 'cash', status: 1 },
      { userName: 'user2', amount: '1.0079', paymentMethod: 'card', status: 2 },
      { userName: 'user3', amount: '1.0079', paymentMethod: 'cash', status: 3 },
      { userName: 'user4', amount: '1.0079', paymentMethod: 'cash', status: 4 },
    ]
  }
];
