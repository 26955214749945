import { Device } from '@capacitor/device';
import { Component, HostListener, Renderer2, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import pageSettings from './config/page-settings';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService, StorageService } from './service';
import { StatusBar, Style } from '@capacitor/status-bar';
import { LoginDevice } from './interface';
const setStatusBarStyleLight = async () => {
  if ((await Device.getInfo()).platform !== 'web') {
    await StatusBar.setStyle({ style: Style.Light });
  }
};
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  Device: LoginDevice;
  pageSettings;

  constructor(
    public config: ConfigService,
    private _router: Router, 
    private _storage: StorageService,
    private _translate: TranslateService,
    private cd: ChangeDetectorRef
  ) {
    window.addEventListener('login:logout', () => { this.logout(); });
    this.initializeApp();
    this._router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        if (window.innerWidth < 768) {
          this.pageSettings.pageMobileSidebarToggled = false;
        }
      }
    });
    setStatusBarStyleLight();
  }
  ngAfterViewInit() {
    this.pageSettings.pageEmpty = true; // u otra operación que cambia el valor
    this.cd.detectChanges();
  }

  ngOnInit() {
    // page settings
    this.pageSettings = pageSettings;
  }

  // window scroll
  pageHasScroll;
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 0) {
      this.pageHasScroll = true;
    } else {
      this.pageHasScroll = false;
    }
  }

  // set page minified
  onToggleSidebarMinified(val: boolean): void {
    if (this.pageSettings.pageSidebarMinified) {
      this.pageSettings.pageSidebarMinified = false;
    } else {
      this.pageSettings.pageSidebarMinified = true;
    }
  }

  // set page right collapse
  onToggleSidebarRight(val: boolean): void {
    if (this.pageSettings.pageSidebarRightCollapsed) {
      this.pageSettings.pageSidebarRightCollapsed = false;
    } else {
      this.pageSettings.pageSidebarRightCollapsed = true;
    }
  }

  // hide mobile sidebar
  onHideMobileSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileSidebarToggled) {
      if (this.pageSettings.pageMobileSidebarFirstClicked) {
        this.pageSettings.pageMobileSidebarFirstClicked = false;
      } else {
        this.pageSettings.pageMobileSidebarToggled = false;
      }
    }
  }

  // toggle mobile sidebar
  onToggleMobileSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileSidebarToggled) {
      this.pageSettings.pageMobileSidebarToggled = false;
    } else {
      this.pageSettings.pageMobileSidebarToggled = true;
      this.pageSettings.pageMobileSidebarFirstClicked = true;
    }
  }


  // hide right mobile sidebar
  onHideMobileRightSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileRightSidebarToggled) {
      if (this.pageSettings.pageMobileRightSidebarFirstClicked) {
        this.pageSettings.pageMobileRightSidebarFirstClicked = false;
      } else {
        this.pageSettings.pageMobileRightSidebarToggled = false;
      }
    }
  }

  // toggle right mobile sidebar
  onToggleMobileRightSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileRightSidebarToggled) {
      this.pageSettings.pageMobileRightSidebarToggled = false;
    } else {
      this.pageSettings.pageMobileRightSidebarToggled = true;
      this.pageSettings.pageMobileRightSidebarFirstClicked = true;
    }
  }
  

  async logout() {
    //this._storage.clear();
    await this._storage.del('token');
    await this._storage.del('IdMerchant');
    await this._storage.del('uuid');
    await this._storage.del('uuidApp');
    await this._storage.del('IdLoginRole');
    await this._storage.del('completeName');
    await this._storage.del('user');
    this._router.navigate(['bo-login']);
  }
  private async initializeApp() {
    const device = await Device.getId();
    const info = await Device.getInfo();
    this.Device = {
      uuidApp: device.uuid,
      Platform: info.platform,
      AppVersion: this.config.version,
      OperatingSystem: info.operatingSystem,
      Model: info.model,
      Manufacturer: info.manufacturer,
      OSVersion: info.osVersion,
    };
    this.initTranslate();
  }

  private initTranslate() {
    // Set the default language for translation strings, and the current language.
    this._translate.setDefaultLang('en');
    const browserLang = this._translate.getBrowserLang();
    if (browserLang) {
      if (browserLang.match(/es|en/)) {
        this._translate.use(browserLang);
        this._storage.set('language', browserLang);
      } else if (browserLang === 'zh') {
        const browserCultureLang = this._translate.getBrowserCultureLang();
        if (browserCultureLang.match(/-CN|CHS|Hans/i)) {
          // this._translate.use('zh-cmn-Hans');
          this._translate.use('en');
          this._storage.set('language', 'en');
        } else if (browserCultureLang.match(/-TW|CHT|Hant/i)) {
          // this._translate.use('zh-cmn-Hant');
          this._translate.use('en');
          this._storage.set('language', 'en');
        }
      } else {
        this._translate.use('es'); // Set your language here
        this._storage.set('language', 'es');
      }
    } else {
      this._translate.use('es'); // Set your language here
      this._storage.set('language', 'es');
    }
  }
}
