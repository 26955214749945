import { Injectable } from '@angular/core';
import { ApiRes } from '../interface/api-res';
import { Location } from '../interface/location';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  row:Location;
  rows:Location[]=[];
  private _method:string;
  constructor(private _api:ApiService) { this.init(); }
  async init() {
    this._method = 'LafiseList';
    this.row = {
      RowNum: 1,
      IdLocation: null,
      Location: null
    }
  }
  fetch() {
    let promise:Promise<ApiRes> = this._api.post(`${this._method}/Get`);
    promise.then((pApiRes:ApiRes) => {
      this.rows = pApiRes.data;
    });
    return promise;
}
}