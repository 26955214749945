import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ApiRes } from '../../../../../interface/api-res';
import { CardidTypeService } from '../../../../../service/cardid-type.service';
import { ComercialActivityService } from '../../../../../service/comercial-activity.service';
import { LocationService } from '../../../../../service/location.service';
import { MaritalService } from '../../../../../service/marital.service';
import { OccupationService } from '../../../../../service/occupation.service';
import { ProfessionService } from '../../../../../service/profession.service';
import { UserListService } from '../../../../../service/user.service';

@Component({
  selector: 'app-merchant-back-office-form',
  templateUrl: './merchant-back-office-form.component.html',
  styleUrls: ['./merchant-back-office-form.component.css']
})
export class MerchantBackOfficeFormComponent implements OnInit {

  patternManager:string = '^[^\\s]+(\\s+[^\\s]+)*$';
  patternPerson:string = '^[^\\s]+(\\s+[^\\s]+)*$';
 
  constructor(
    private formBuilder: FormBuilder,
    public provider: UserListService,
    public providerCardIdType: CardidTypeService,
    public providerComercialActivity: ComercialActivityService,
    public providerLocation: LocationService,
    public providerMaritalStatus: MaritalService,
    public providerProfession: ProfessionService,
    public providerOccupation: OccupationService,
    ) { }

    ngOnInit(): void {
    if (this.provider.action == 'Add') {
      this.provider.init();
      this.provider.row.Status = 1;
      this.provider.row.IdLoginRole = 4;
      // this.providerCardIdType.fetch().then((pApiRes:ApiRes) => {
      //   if(pApiRes.ok){

      //   }
      // });
      this.providerCardIdType.fetch().finally(() =>{
        if(this.providerCardIdType.rows.length>0){
          this.provider.row.IdCardIdType = this.providerCardIdType.rows[0].IdCardIdType;
          const obj = this.providerCardIdType.rows.find(item => item.IdCardIdType == this.provider.row.IdCardIdType);
          this.patternPerson = obj.Regex;//this.providerCardIdType.rows[this.provider.row.IdCardIdType].Regex;
        }
      });
      this.provider.row.MonthsBusinessHistory = 0;
      this.provider.row.Gender = 'M';
      this.provider.businessProfessionalSelected = 1;
    }
    if(this.provider.action == 'Edit'){
      this.providerCardIdType.fetch().finally(() =>{
        if(this.providerCardIdType.rows.length>0){
          this.provider.row.IdCardIdType = this.provider.row.IdCardIdType;
        }
      });
      this.provider.row.Gender = this.provider.row.Gender;
    }
  }

  return(){
    this.provider.currentStep.stepIndex = this.provider.currentStep.stepIndex-1;
  }
  onSubmit(event:any){

  }
  
  onlyPhone(event: KeyboardEvent) {
    // Permitir solo números del 0 al 9
    let pattern = /[0-9\+]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // Cancelar la entrada de caracteres no numéricos
      event.preventDefault();
    }
  }

  pasteValidationPhone(event: ClipboardEvent) {
    // Obtener el texto que se va a pegar
    let textoPegado = (event.clipboardData || (window as any).clipboardData).getData('text');
    // Definir un patrón que acepte solo números
    let pattern = /^\+\d{0,11}$/;

    // Si el texto pegado no coincide con el patrón, cancelar la acción de pegado
    if (!pattern.test(textoPegado)) {
      event.preventDefault();
    }
  }

  // Manejador del evento keypress
  onlyNumbersKeyPress(event: KeyboardEvent) {
    const pattern = /[0-9]/; // regex para solo numeros
    const inputChar = String.fromCharCode(event.charCode);

    // Si el caracter no es un número, previene la entrada
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // Manejador del evento paste
  onlyNumbersPaste(event: ClipboardEvent) {
    const pattern = /^[0-9]*$/; // regex para solo numeros
    const pastedText = (event.clipboardData || (window as any).clipboardData).getData('text');

    // Si el texto pegado no es solo numeros, previene la acción de pegado
    if (!pattern.test(pastedText)) {
      event.preventDefault();
    }
  }

  changeCardIdValid(id: number){
    if(this.providerCardIdType.rows.length>0){
      
      if (id===1) {
        this.provider.row.CardId = null;
        const obj = this.providerCardIdType.rows.find(item => item.IdCardIdType == this.provider.row.IdCardIdType);
        this.patternPerson = obj.Regex;//this.providerCardIdType.rows[this.provider.row.IdCardIdType].Regex;
      } else {
        this.provider.row.ManagerCardId = null
        const obj = this.providerCardIdType.rows.find(item => item.IdCardIdType == this.provider.row.ManagerCardIdType);
        this.patternManager = obj.Regex;//this.providerCardIdType.rows[this.provider.row.ManagerCardIdType].Regex;
      }

    }
  }
}
