import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() { this.init(); }
  async init() {
    //PublicKey para enviar al API
    await this.set(
      'publicKey',
      `-----BEGIN PUBLIC KEY-----
      MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAx1N4NPylC1NO+3l3Y7c0
      oGGbRYbiAxyvtxRU5ccnn7yq/BfWsahNESYZh7456T+aaFCpzhI7pBcNBqWz8HKH
      jX/LaRWpD62LBvLVLl/qBkMXFsOX6bBBUihYk2S70MRzS5lEkaoieECtc4cZD3Hu
      qcUKGSEhZ358PK+QvWnDX3v0c+G8ClmIl9d+hguQldpMm74D6xZ+rQ7LgD8InnZ/
      R0PBmfi8wqjyyQ3rIjOdzsfrlLLVEFNTLZLWIJ3xN3nXhihCFdEtk30HuibCtzUI
      P26k7fcXuaQ0Il2lUXsgeircPouvfFjxFWh2wUn3+bDQLPNaBHwqCFmP9tYkygbC
      FwIDAQAB
      -----END PUBLIC KEY-----`
    );
    //PrivateKey para recibir desde el API
    await this.set(
      'privateKey',
      `-----BEGIN RSA PRIVATE KEY-----
      MIIEowIBAAKCAQEAwiSxcx5xFsNCjfDTOXz0M6RivgPXTTKb/PH6/x4vYl1xg/vI
      rG1yDGyFuyAizxNaGtA+qo2CvM3iATyDWQg3/8vDWiD4cIvUD2WE3XXewjyeGzQs
      o26DceRxopI2tlSgvznjifetGCp+oj4BWrTCqLF1AsR2ioUS7vuXMlpxXFhJfyEG
      oWuQaZDwoz7CZv1KrQwlRTtkqtn4IeXpVcgWhg/1r0iRsvNJDokyiMVY8hrrvza2
      j31JGaKYqCNYSC8Jf5EV7ONQhYGgQeRVxn8jS8UhLOFXDdNtgHwKi02tF1bmb3Ko
      /s3vzPvFB8C7CsYRtHAzcx/xor2/CbT0QGYfUQIDAQABAoIBADgdoQj0UJ3SvKcI
      aBViz7cpmbzwoUfYDAx16SXalLmq5sfOfDeSvvdmWtU2ubj/D/lWHfbwRkzKebv/
      wLt4S69tNz8S7pyhXh9BKcFVc5jTKqQUVZ67r9S4wjvKZXQYTApZ8jIL3AVzKv08
      TOnp+6YE/RxaqyToyAs5v383czLMdSM9bThDw+IZHfKFcl2iyUI4XVaPLDZPzUlG
      MW13ypwFQXbdro9pnMs9v2573WumIVvbiLqIdxK1anag0KbCnCY2hDmoLNv7Mohz
      1pFiqEStI6DQsYfEm/geIIL9nLhA8QJ28B9DtLKqRN7oWl2w5BiMEDCrmVK/4niM
      lsqF1ZECgYEA5zVsjoJzRCBgZ5dRx9BOu9MKXQ+h00LS3+JaApEzW+QV0DplTvb5
      aRUHhTIhUig2WdQVrEiBVXKg9eYmOBXOpX5pgDO5XJ16KcjdHcwMwoYAcUPIT5Y2
      tMgoX+jCgTt95cnLKhAnSlH9cCyq+nmcdfDNuvNx8HJO7P33MaVDHCUCgYEA1vXX
      cBWM+sGkUxpJNwlgA8bO7xq2kpbM3gqa0z6SKQhxnnmPyoKsZSHdwQMUCKIWBLjU
      n27STmXm2SblT1cT39MCED2t0U7N2D1KUQfZkFaN4gImF77iZBWIfDLObAom6JKg
      gER0glakAjbDMKq90mVzrKrR6XsD3az+AJE1eL0CgYBbHKB04E9QD3ouGGFv3lTI
      i3fQCHL676Bt/aL+0/1rPsyhzAFURtsuX80g3gpnd9VDPOJ1i/T7mTp47IMvItjW
      OqSUks1/A8e6Y59POLPmjCvsdoufYVCZmS7f7LeJeco5HXZkUw1Iqlq3M8MFBZt0
      lrpb68eAu2sC1WnuNHPnyQKBgQC1LFb7vO4pmnOpJwp2PIyUIkfe9qDSRA8/Rajg
      Smhd9SPt8X7jq+cpBbYlKzcDX7k3GaD7DyhpszEx7LpweG/jwbCHh7SsKMMNcfrk
      +LzCDnFe/3ijotqkiBGUvC2GmbfZZVupQAkCoYtx4j35Eq8SWTd5XC+3nVrQxzD9
      wWVT8QKBgBylVSsVeU7H/RT3RZ/qgWnpllBzGJic8X6lfyTHVD/9dO9XMzuD7R+/
      VFc9Ib71QPzyq39BX+YiCI+xwDGARgO3GGA4vxvF46Tq0rYUpZ1LiC4raO4I5xrl
      NxZCLVlhLWU27FR7xu8YN18mRCCwz1FCcsEtNU4nrBK2IIIwGLCf
      -----END RSA PRIVATE KEY-----`
    );
  }
  // Create and expose methods that users of this service can
  // call, for example:
  async set(pKey: string, pValue: any) {
    await Storage.set({
      key: pKey,
      value: pValue,
    });
  }
  async get(pKey: string) {
    const { value } = await Storage.get({ key: pKey });
    return value;
  }
  async del(pKey: string) {
    await Storage.remove({ key: pKey });
  }
  async clear() {
    await Storage.clear();
  }
}
