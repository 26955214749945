import { Injectable } from '@angular/core';
import * as global from '../config/globals';
import { TableOptions, ApiRes, Home } from '../interface';
import { ApiService } from './api.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { StorageService } from './storage.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  delProcessing: boolean = false;
  editProcessing: boolean = false;
  addProcessing: boolean = false;
  getProcessing: boolean = false;
  row: Home;
  barChartData;
  barChartOptions;
  stackedBarChartData;
  stackedBarChartOptions;
  private _method: string = 'Merchant';
  search: TableOptions = {
    search: null,
    page: 1,
    count: 0,
    size: 10,
    sizes: [10, 25, 50, 100],
    sort: null,
    params: {
      IdMerchant: 5,
      HomeGet: 1,
    },
    columnMode: ColumnMode,
    columnActions: (window.innerWidth < 960) ? 1 : 4//Inicia en cero
  };
  constructor(
    private _api: ApiService,
    private _storage: StorageService,
    public translate: TranslateService,
    public config: ConfigService,
  ) { 
    this.init();
    this.search.params = {
      IdMerchant: this._storage.get('IdMerchant'),//this.config.User.IdMerchant,
      HomeGet: 1,
    }
  }
  init() { this._initRow();}
  fetch(pAppend: boolean = false): Promise<ApiRes> {
    this._initRow();
    this.search.params.IdMerchant = this._storage.get('IdMerchant');
    this.getProcessing = true;
    const promise: Promise<ApiRes> = this._api.post(`${this._method}/Get`, this.search.params);
    promise.then((pApiRes: ApiRes) => {
      if (pApiRes.ok) {
        this.row = pApiRes.data;
        this.loadStackedChart();
        //this._addRows(pAppend, pApiRes.data);
      } else {
        this._initRow();
      }
      this.getProcessing = false;
    });
    return promise;
  }
  private _initRow() { this.row = {}; }

  loadChart(){
    if (this.row.Last6MonthsTransactions) { //empieza el if
      const colorChart = [global.COLOR_RED, global.COLOR_ORANGE, global.COLOR_GREEN, global.COLOR_AQUA,
        global.COLOR_BLUE, global.COLOR_PURPLE, global.COLOR_GREY, global.COLOR_BLACK];
  
      let arrayChart = [];
      for (let index = 0; index < this.row.Last6MonthsTransactions.length; index++) {
        const amountMonth = this.row.Last6MonthsTransactions[index];
        let rowChart = {};
        rowChart['label'] = amountMonth['NombreDelMes'];
        rowChart['value'] = amountMonth['Total'];
        rowChart['color'] = colorChart[index];
        arrayChart.push(rowChart);
      }
  
      this.barChartData = [{
        key: 'Cumulative Return',
        values: arrayChart
      }];
  
      let labelY;
      let labelX;
  
      this.translate.get('AMOUNT').subscribe(
        (pTranslation) => {
          labelY = pTranslation;
        }
      );
      this.translate.get('AMOUNT_TRANSACTION_BY_MONTH').subscribe(
        (pTranslation) => {
          labelX = pTranslation;
        }
      );
  
      this.barChartOptions = {
        chart: {
          type: 'discreteBarChart',
          height: 250,
          x: function(d){return d.label;},
          y: function(d){return d.value;},
          showValues: true,
          duration: 250,
          yAxis: {
            axisLabel: labelY
          },
          xAxis: {
            axisLabel: labelX
          }
        }
      }
    }//se termina el if
    
  }

  loadStackedChart() {
    let arrayChart = [];
    let rowChartColones = {};
    let rowChartDollars = {};

    rowChartColones['key'] = 'Colones';
    rowChartColones['color'] = global.COLOR_BLACK_DARKER;
    rowChartColones['values'] = [];

    rowChartDollars['key'] = 'Dolares';
    rowChartDollars['color'] = global.COLOR_RED_DARKER;
    rowChartDollars['values'] = [];

    if (this.row.Last6MonthsTransactions) {
      this.row.Last6MonthsTransactions.forEach(element => {
        let data = {};
        data['x'] = element.NombreDelMes;
        data['y'] = element.Total;
        rowChartColones['values'].push(data);
      });
      //arrayChart.push(rowChartColones);
    }
    
    if (this.row.Last6MonthsTransactionsDollars) {
      this.row.Last6MonthsTransactionsDollars.forEach(element => {
        let data = {};
        data['x'] = element.NombreDelMes;
        data['y'] = element.Total;
        rowChartDollars['values'].push(data);
      });
      //arrayChart.push(rowChart);
    }
    if (rowChartColones != null) {
      if (rowChartDollars != null) {
        this.validarArreglos(rowChartColones['values'], rowChartDollars['values']);
        arrayChart.push(rowChartColones);
        arrayChart.push(rowChartDollars);
      }
      else {
        arrayChart.push(rowChartColones);
      }
    } else {
      if (rowChartDollars != null) {
        arrayChart.push(rowChartDollars);
      }
    }
    this.stackedBarChartData = arrayChart;

    this.stackedBarChartOptions = {
      chart: {
        type: 'multiBarChart',
        height: 250,
        stacked: false,
        showControls: true
      }
    }
  }

  validarArreglos(primerArreglo, segundoArreglo) {
    primerArreglo.forEach(obj => {
      if (!this.existeObjeto(obj, segundoArreglo)) {
        segundoArreglo.push({'x': obj.x, 'y': 0});
      }
    });

    segundoArreglo.forEach(obj => {
      if (!this.existeObjeto(obj, primerArreglo)) {
        primerArreglo.push({'x': obj.x, 'y': 0});
      }
    });

  }

  existeObjeto(objeto: any, arreglo: any[]): boolean {
    return arreglo.some(item => item.x === objeto.x);
  }
  
}
