import { Component, OnDestroy, Renderer2, OnInit, ChangeDetectorRef } from '@angular/core';
import pageSettings from '../../../config/page-settings';
import { LoginDeviceService, LoginService } from '../../../service';

@Component({
  selector: 'app-login',
  templateUrl: './bo-login.component.html',
  styleUrls: ['./bo-login.component.css'],
})
export class BoLoginComponent implements OnDestroy, OnInit {
  pageSettings = pageSettings;
  constructor(
    private renderer: Renderer2,
    public provider: LoginService,
    public loginDeviceServiceProvider: LoginDeviceService,
    private cd: ChangeDetectorRef
  ) {
    this.pageSettings.pageEmpty = true;
    this.loginDeviceServiceProvider.init();
    this.renderer.addClass(document.body, 'bg-white');
  }
  async ngOnInit(): Promise<void> {
    await this.loginDeviceServiceProvider.init();
    await this.loginDeviceServiceProvider.addEdit('v1.0.0');
  }
  ngOnDestroy() {
    this.pageSettings.pageEmpty = false;
    this.renderer.removeClass(document.body, 'bg-white');
  }
}
