import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService, BranchService } from '../../../../service';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.css']
})
export class BranchComponent implements OnInit {

  idMerchant: number;
  nameMerchant: string;

  constructor(
    public provider: BranchService,
    public config: ConfigService,
    private route: ActivatedRoute,
  ) { 
    this.provider.init();
    this.route.params.subscribe(params => {
      this.idMerchant = params.IdMerchant;
      this.provider.search.params.IdMerchantBusiness = this.idMerchant;
      this.nameMerchant = params.NameMerchant;
      this.provider.row.IdMerchantBusiness = params.IdMerchant;
    });
  }

  ngOnInit(): void {
  }

}
