import { Injectable } from '@angular/core';
import { ApiRes } from '../interface/api-res';
import { ComercialActivity } from '../interface/comercial-activity';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ComercialActivityService {
  row:ComercialActivity;
  rows:ComercialActivity[]=[];
  private _method:string;
  constructor(private _api:ApiService) { this.init(); }
  async init() {
    this._method = 'ComercialActivity';
    this.row = {
      IdComercialActivity: 0,
      ComercialActivity: null,
    }
  }
  fetch() {
    let promise:Promise<ApiRes> = this._api.post(`${this._method}/Get`);
    promise.then((pApiRes:ApiRes) => {
      this.rows = pApiRes.data;
    });
    return promise;
}
}