

import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalService } from '../../../../../service';
import { UserService } from '../../../../../service/user.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent {

  constructor(
    private router: Router,
    private _globalService: GlobalService,
    public provider: UserService,

  ) { }

  async formSubmit(f: NgForm) {

    await this.provider.newUser();
    if (this.provider.error != 'yes') {
      this._globalService.showAlert('Error', this.provider.error);
    }
    else {
      this._globalService.showAlert('Registro completado', 'Usuario registrado con exito', 'success');
      this.router.navigate(['bo-userlist']);
    }
  }
}
