import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiRes } from '../../../../../interface/api-res';
import { TerminalService, CardidTypeService, MaritalService
            , UserListService, ComercialActivityService, LocationService
            , ProfessionService, OccupationService } from '../../../../../service';
import { UserData } from '../../../../../interface/user';

@Component({
  selector: 'app-terminal-form',
  templateUrl: './terminal-form.component.html',
  styleUrls: ['./terminal-form.component.css']
})
export class TerminalFormComponent implements OnInit {

  patternPerson:string = '^[^\\s]+(\\s+[^\\s]+)*$';
  IdMerchant:number = 0

  constructor(
    public provider: TerminalService,
    private route: ActivatedRoute,
    public providerUserList: UserListService,
    public providerCardIdType: CardidTypeService,
    public providerComercialActivity: ComercialActivityService,
    public providerLocation: LocationService,
    public providerMaritalStatus: MaritalService,
    public providerProfession: ProfessionService,
    public providerOccupation: OccupationService,
  ) {
    this.providerUserList.init();
    this.providerUserList.initSearch(6)
    if(this.provider.action == "Add"){
      this.provider.init();
      this.providerUserList.row.Status = 1;
      this.providerUserList.row.IdLoginRole = 6;
      

      this.route.params.subscribe(params => {
        this.provider.row.IdBranch = params.IdBranch;
        this.providerUserList.row.IdBranch = params.IdBranch;
        //this.provider.row.IdMerchant = params.IdMerchant;
      });

      this.providerCardIdType.fetch().finally(() =>{
        if(this.providerCardIdType.rows.length>0){
          this.providerUserList.row.IdCardIdType = this.providerCardIdType.rows[0].IdCardIdType;
          const obj = this.providerCardIdType.rows.find(item => item.IdCardIdType == this.providerUserList.row.IdCardIdType);
          this.patternPerson = obj.Regex;//this.providerCardIdType.rows[this.provider.row.IdCardIdType].Regex;
        }
      });
      this.providerMaritalStatus.fetch().finally(() =>{
        if(this.providerMaritalStatus.rows.length>0){
          this.providerUserList.row.IdMaritalStatus = this.providerMaritalStatus.rows[0].IdMaritalStatus;
        }
      });
      this.providerProfession.fetch().finally(() =>{
        if(this.providerProfession.rows.length>0){
          this.providerUserList.row.IdProfession = this.providerProfession.rows[0].IdOccupation;
        }
      });
      this.providerOccupation.fetch().finally(() =>{
        if(this.providerOccupation.rows.length>0){
          this.providerUserList.row.IdOccupation = this.providerOccupation.rows[0].IdOccupation;
        }
      });
      this.providerUserList.row.Gender = 'M';
      this.providerUserList.businessProfessionalSelected = 1;
    
    }

    if(this.provider.action == 'Edit'){
      this.providerUserList.search.search = 'IdMerchant';
      this.providerUserList.search.params = {
        IdMerchant: this.provider.row.IdMerchant,
        IdLoginRole: 6
      }
      this.providerUserList.fetch().then((pApiRes:ApiRes) => {
        if (pApiRes.ok) {
          this.providerUserList.row = pApiRes.data;
          this.providerUserList.row.IdTerminal = this.provider.row.IdTerminal;
        } else {
          
        }
      });

      this.route.params.subscribe(params => {
        this.provider.row.IdBranch = params.IdBranch;
        this.IdMerchant = params.IdMerchant;
        //this.provider.row.IdMerchant = params.IdMerchant;
      });
      this.providerCardIdType.fetch().finally(() =>{
        if(this.providerCardIdType.rows.length>0){
          this.providerUserList.row.IdCardIdType = this.providerUserList.row.IdCardIdType;
        }
      });
      this.providerMaritalStatus.fetch().finally(() =>{
        if(this.providerMaritalStatus.rows.length>0){
          this.providerUserList.row.IdMaritalStatus = this.providerUserList.row.IdMaritalStatus;
        }
      });
      this.providerProfession.fetch().finally(() =>{
        if(this.providerProfession.rows.length>0){
          this.providerUserList.row.IdProfession = this.providerUserList.row.IdProfession;
        }
      });
      this.providerOccupation.fetch().finally(() =>{
        if(this.providerOccupation.rows.length>0){
          this.providerUserList.row.IdOccupation = this.providerUserList.row.IdOccupation;
        }
      });
      this.providerUserList.row.Gender = this.providerUserList.row.Gender;
      // this.providerCardIdType.fetch();
      // this.providerUserList.search.search = 'IdMerchant';
      // this.providerUserList.search.params.IdMerchant = this.provider.row.IdMerchant;
      // this.providerUserList.fetch().then((pApiRes:ApiRes) =>{
      //   if(pApiRes.ok){
      //     if(pApiRes.data!=null){
      //       this.providerUserList.row = pApiRes.data as UserData;
      //       this.providerUserList.row.IdCardIdType = this.providerUserList.row.IdCardIdType;
      //     }
      //   }
      // });
      // this.providerMaritalStatus.fetch().finally(() =>{
      //   if(this.providerMaritalStatus.rows.length>0){
      //     this.providerUserList.row.IdMaritalStatus = this.providerUserList.row.IdMaritalStatus;
      //   }
      // });
      // this.providerCardIdType.fetch().finally(() =>{
      //   if(this.providerCardIdType.rows.length>0){
      //     this.providerUserList.row.IdCardIdType = this.providerUserList.row.IdCardIdType;
      //   }
      // });
    }

   }

  ngOnInit(): void {
  }

  edit(){
    this.providerUserList.edit().then((pApiRes:ApiRes) =>{
      if (pApiRes.ok) {
        // this.provider.row.IdMerchant = (pApiRes.data as UserData).IdMerchant;
        // this.provider.add();
        this.provider.action = 'List';
        this.provider.fetch();
        this.providerUserList.init();
      }
      else{
        
      }
    });
  }
  add(){
    this.providerUserList.add().then((pApiRes:ApiRes) =>{
      if (pApiRes.ok) {
        // this.provider.row.IdMerchant = (pApiRes.data as UserData).IdMerchant;
        // this.provider.add();
        this.provider.action = 'List';
        this.provider.fetch();
        this.providerUserList.init();
      }
      else{
        
      }
    });
  }

  completeStep1(){
    this.providerUserList.currentStep.stepIndex=2;
  }
  return(){
    this.providerUserList.currentStep.stepIndex = this.providerUserList.currentStep.stepIndex-1;
  }

  onlyPhone(event: KeyboardEvent) {
    // Permitir solo números del 0 al 9
    let pattern = /[0-9\+]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // Cancelar la entrada de caracteres no numéricos
      event.preventDefault();
    }
  }

  pasteValidationPhone(event: ClipboardEvent) {
    // Obtener el texto que se va a pegar
    let textoPegado = (event.clipboardData || (window as any).clipboardData).getData('text');
    // Definir un patrón que acepte solo números
    let pattern = /^\+\d{0,11}$/;

    // Si el texto pegado no coincide con el patrón, cancelar la acción de pegado
    if (!pattern.test(textoPegado)) {
      event.preventDefault();
    }
  }

  // Manejador del evento keypress
  onlyNumbersKeyPress(event: KeyboardEvent) {
    const pattern = /[0-9]/; // regex para solo numeros
    const inputChar = String.fromCharCode(event.charCode);

    // Si el caracter no es un número, previene la entrada
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // Manejador del evento paste
  onlyNumbersPaste(event: ClipboardEvent) {
    const pattern = /^[0-9]*$/; // regex para solo numeros
    const pastedText = (event.clipboardData || (window as any).clipboardData).getData('text');

    // Si el texto pegado no es solo numeros, previene la acción de pegado
    if (!pattern.test(pastedText)) {
      event.preventDefault();
    }
  }

  changeCardIdValid(id: number){
    if(this.providerCardIdType.rows.length>0){
      
      if (id===1) {
        this.providerUserList.row.CardId = null;
        const obj = this.providerCardIdType.rows.find(item => item.IdCardIdType == this.providerUserList.row.IdCardIdType);
        this.patternPerson = obj.Regex;//this.providerCardIdType.rows[this.provider.row.IdCardIdType].Regex;
      }
    }
  }

}
