import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { from, Observable } from 'rxjs';
import { StorageService } from '../service';
@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {
  constructor(private _storage:StorageService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return from(this._handle(route, state));
  }
  private async _handle(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = await this._storage.get('token');
    if (!token) {
      window.dispatchEvent(new CustomEvent('login:logout'));
    } else {
      return true;
    }
  }
}