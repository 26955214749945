import { MerchantRequest } from './../interface/merchant';
import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { ApiRes, Login, LoginDevice, LoginToken } from '../interface';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';
@Injectable({
  providedIn: 'root'
})
export class LoginTokenService {
  row:LoginToken;
  private _method:string;
  constructor(
    private _api:ApiService,
  ) {
    this._method = 'LoginToken';
    this.init();
  }
  init() {
    this.row = {
      IdToken    : null,
      IdLogin    : null,
      Created    : null,
      Token      : null,
      Email      : null,
      Phone      : null,
      SendedSMS  : null,
      SendedEmail: null,
    }
  }
  add() {
    let promise:Promise<ApiRes> = this._api.post(`${this._method}/Add`, this.row);
    return promise;
  }
}
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  loginProcessing:boolean;
  loginError:string;
  row:Login;
  private _method:string;
  constructor(
    private _api:ApiService,
    private router: Router, 
    private _storage:StorageService,
    private _global:GlobalService,
  ) {
    this._method = 'Login';
    this.init();
  }
  async init() {
    await this._storage.del('token');
    const type = await this._storage.get('type');
    this.row = {
      User:null,
      Type:type ?? 'BackOffice',//Merchant|User|BackOffice
      Password:null,
      ConfirmPassword:null,
      NewPassword:null,
      ChangePassword:null,
      Active:1,
      Phone: null,
      Email: '',
      IdLoginRole:null,
      IdCountry:null,
      BiometricLogin: false,
      LoginAttempt:0,
      Token: ''
    }
  }
  async cleanRow() {
    this.row = {
      User:null,
      Type:null,
      Password:null,
      ConfirmPassword:null,
      NewPassword:null,
      ChangePassword:null,
      Active:null,
      Phone: null,
      Email: null,
      IdLoginRole:null,
      IdCountry:null,
      BiometricLogin: null,
      LoginAttempt:null,
      Token: null
    }
  }
  async login() {
    this.loginProcessing = true;
    let promise:Promise<ApiRes> =  this._api.post(`${this._method}/Login`, this.toSend());
    await promise.then(async (pApiRes: ApiRes) => {
      if (pApiRes.ok) {
        this.loginError = null;
        //await this._storage.set('token', pApiRes.data.MerchantRow.Token);
        await this._storage.set('IdMerchant', pApiRes.data.MerchantRow.IdMerchant);
        await this._storage.set('IdLoginRole', pApiRes.data.MerchantRow.IdLoginRole);
        await this._storage.set('completeName', pApiRes.data.MerchantRow.NameMerchant + " " + pApiRes.data.MerchantRow.LastNameMerchant);
        this.row.IdLogin = pApiRes.data.MerchantRow.IdLogin;
        this.row.Email = pApiRes.data.MerchantRow.Email;
        this.row.User = pApiRes.data.MerchantRow.User;
        this.row.BiometricLogin = pApiRes.data.MerchantRow.BiometricLogin;
        //this.router.navigate(['bo-merchant']);
        this.router.navigate(['home']);
      } else {
        this.loginError = pApiRes.info;
      }
      this.loginProcessing = false;
      this.row.Password = null;
    });
    return promise;
  }
  async edit() {
    this.loginProcessing = true;
    let promise:Promise<ApiRes> =  this._api.post(`${this._method}/Edit`, this.row);
    await promise.then(async (pApiRes: ApiRes) => {
      if (pApiRes.ok) {
        this.loginError = null;
        this._global.showAlert("", pApiRes.info, 'success');
        //this.router.navigate(['bo-merchant']);
      } else {
        this.loginError = pApiRes.info;
      }
      this.loginProcessing = false;
      this.row.Password = null;
    });
    return promise;
  }
  private toSend() {
    let toSend = {...this.row};
    if (toSend.Phone) {
      toSend.User = `+506${toSend.Phone}`;
      toSend.Phone = null;
    }
    if (toSend.Email) {
      toSend.User = toSend.Email;
      toSend.Email = null;
    }
    return toSend;
  }
}
@Injectable({
  providedIn: 'root'
})
export class LoginDeviceService {
  row:LoginDevice;
  private _method:string;
  constructor(
    private _api:ApiService,
    private _storage:StorageService,
  ) {
    this._method = 'LoginDevice';
    this.init();
  }
  async init() {
    const device = await Device.getId();
    const info = await Device.getInfo();
    this.row = {
      uuidApp        : device.uuid,
      Platform       : info.platform,
      AppVersion     : 'v1.0.0',
      OperatingSystem: info.operatingSystem,
      Model          : info.model,
      Manufacturer   : info.manufacturer,
      OSVersion      : info.osVersion,
    }
  }
  async addEdit(pVersion):Promise<ApiRes> {

    if (pVersion) { this.row.AppVersion = pVersion; }
    let promise:Promise<ApiRes> = this._api.post(`${this._method}/AddEdit`, this.row);
    await promise.then(async (pApiRes:ApiRes) => {

      if (pApiRes.ok) { await this._storage.set('uuidApp', this.row.uuidApp); }
    });
    return promise;
  }
}

export interface ApiResTest {
  status           : string;
  message         : string;
  data         : any;
  comment?: string;
}


