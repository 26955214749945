import { Component, ViewChild, AfterViewInit, Input } 		 from '@angular/core';

@Component({
  selector: 'panel',
  inputs: [
    'title', 'variant', 'noBody', 'noButton', 'bodyClass', 'footerClass', 'panelClass', 'noHead', 'reload',
    'noFull', 'noMin', 'noRefresh', 'noClose'
  ],
  templateUrl: './panel.component.html'
})

export class PanelComponent implements AfterViewInit {
  @ViewChild('panelFooter', { static: false }) panelFooter;
  @Input() fReload: Function = () => { this.reload = true; setTimeout(() => { this.reload = false; }, 1500); };
  expand = false;
  reload = false;
  collapse = false;
  remove = false;
  showFooter = false;

  ngAfterViewInit() {
    setTimeout(() => {
      this.showFooter = this.panelFooter.nativeElement && this.panelFooter.nativeElement.children.length > 0;
    });
  }

  panelExpand() {
    this.expand = !this.expand;
  }
  panelReload() {
    this.fReload();
  }
  panelCollapse() {
    this.collapse = !this.collapse;
  }
  panelRemove() {
    this.remove = !this.remove;
  }
}
